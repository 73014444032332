// Copyright 2020 @po-polochkam authors & contributors

import type { CustomerReportPhotoResultType, ReportResultType } from 'api/abstractions/result';
import { SmallProduct } from 'api/abstractions/dictionary';
import { Spin, Space, Empty } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import firebase from 'firebase';
import useResultGroup from 'hooks/useResultGroup';
import AdminModalHeader from './AdminModalHeader';
import CustomerModalHeader from './CustomerModalHeader';
import ResultsImageGallery from '../ResultsImageGallery';
import ChatComponent from '../ChatComponent';
import './styles.less';
import LackOfProducts from 'components/LackOfProducts';
import ShopAllReports from 'components/ShopAllReports';

interface Props {
  admin?: boolean;
  firebaseDb?: firebase.database.Database;
  toggleResultModal?: (isOpen: boolean) => void;
}

const DiscussionModalContent: React.FC<Props> = ({ admin, firebaseDb, toggleResultModal }) => {
  const { t } = useTranslation();
  const { resultId } = useParams() as unknown as { resultId: string };
  const { fetchProductsByResult, fetchResultGroup, loadingResultGroup, resultProducts, selectedResultGroup } = useResultGroup(resultId);
  const [resultToCheck, setResultToCheck] = useState<ReportResultType>();
  const customerDailyResultId: string | undefined = selectedResultGroup?.customerDailyResultId;
  const cityName: string = selectedResultGroup?.customerDailyResult?.shopAddress?.cityName || '';
  const regionAreaName: string = selectedResultGroup?.customerDailyResult?.shopAddress?.regionAreaName || '';
  const streetName = selectedResultGroup?.customerDailyResult?.shopAddress?.street || '';
  const address = [streetName, cityName, regionAreaName].join(', ');
  const [galleryActiveImage, setGalleryActiveImage] = useState<string>('');
  const [reportId, setResportId] = useState<string>('');

  const openResultGallery = useCallback((item: ReportResultType) => {
    setResultToCheck(item);
  }, []);

  const productItem = useCallback((productId) => {
    const targetProduct = resultProducts.find((product: SmallProduct) => product.id === productId);

    if (targetProduct) {
      return (
        <div
          className='errors-block--errors--error-image'
          key={productId}
        >
          { targetProduct.imagePath && (
            <img
              alt={targetProduct.name}
              src={targetProduct.imagePath}
            />
          )}
          <span>{targetProduct.name}</span>
        </div>
      );
    }
  }, [resultProducts]);

  useEffect(() => {
    if (customerDailyResultId) {
      if (selectedResultGroup?.reportResults[0]) { setResportId(selectedResultGroup?.reportResults[0].id); }

      void fetchProductsByResult(customerDailyResultId);
    }
  }, [customerDailyResultId, fetchProductsByResult, selectedResultGroup?.reportResults]);

  useEffect(() => {
    void fetchResultGroup();
  }, [fetchResultGroup, resultId]);

  return (
    <div className='discussion-modal-content'>
      { loadingResultGroup && (
        <Space size='middle'>
          <Spin
            size='large'
            tip={t('loading')}
          />
        </Space>
      )}
      { admin && (
        <AdminModalHeader
          activeImage={galleryActiveImage}
          address={address}
          selectedResultGroup={selectedResultGroup}
          toggleResultModal={toggleResultModal}
        />
      )}
      { !admin && (
        <CustomerModalHeader
          address={address}
          selectedResultGroup={selectedResultGroup}
          toggleResultModal={toggleResultModal}
        />
      )}
      { !selectedResultGroup?.reportResults?.length && !loadingResultGroup && (
        <Empty
          description={t('there are no results on current date')}
        />
      )}
      { selectedResultGroup?.reportResults.map((reportResult: ReportResultType) => (

        <div
          className='errors-block'
          key={reportResult.id}
        >
          <div className='errors-block--example'>
            <img
              alt='photoReport'
              onClick={() => {
                openResultGallery(reportResult);
                setGalleryActiveImage(reportResult.photoReport.imagePath);
              }}
              src={reportResult.photoReport.imagePath}
            />
          </div>
          { !reportResult.customerReportPhotoResults.length && (
            <div />
          )}
          { reportResult.customerReportPhotoResults.length === 1 && (
            <div className='errors-block--customer-image'>
              <div className='errors-block--customer-images--image--index'>1</div>
              <img
                alt='customer-photo'
                onClick={() => {
                  openResultGallery(reportResult);
                  setGalleryActiveImage(reportResult.photoReport.imagePath);
                }}
                src={reportResult.customerReportPhotoResults[0].imagePath}
              />
            </div>
          )}
          { reportResult.customerReportPhotoResults.length > 1 && (
            <div className='errors-block--customer-images'>
              { reportResult.customerReportPhotoResults.map((image, index) => (
                <div
                  className='errors-block--customer-images--image'
                  key={image.imagePath}
                >
                  <div className='errors-block--customer-images--image--index'>{index + 1}</div>
                  <img
                    alt='customer-photo'
                    onClick={openResultGallery.bind(null, reportResult)}
                    src={image.imagePath}
                  />
                </div>
              ))}
            </div>
          )}
          {/* GET /daily-results/{id}/skus */}
          <div className='errors-block--errors'>
            { reportResult?.reportResultReportMistakes.map((mistake) => (
              <div
                className='errors-block--errors--error'
                key={mistake.id}
              >
                { mistake.comment || mistake.reportResultMistake.name }
                { mistake.productsIds?.map((productId) => productItem(productId))}
              </div>
            ))}
            <div className='errors-block--errors--comments'>
              <div className='error-selected--header'>{t('performers comments')}</div>
              { reportResult?.customerReportPhotoResults?.map((protoResult: CustomerReportPhotoResultType, index: number) => {
                if (protoResult.comment) {
                  return (
                    <div
                      className='error-selected--comment'
                      key={protoResult.imagePath}
                    >
                      {index + 1}. {protoResult.comment}
                    </div>
                  );
                }
              })}
            </div>
            { (customerDailyResultId) && (
              <LackOfProducts
                categoryId={reportResult.productCategory.id}
                reportId={reportResult.photoReport.id}
                resultId={customerDailyResultId}
              />)
            }
            { (reportResult.customerReportPhotoResults[0]?.reportAssessment && reportResult.customerReportPhotoResults[0]?.reportAssessment.madeBy === 'automatic') && (
              <div className='errors-block--errors--comments'>
                <div className='error-selected--header'>{t('recognition solution')}</div>
                <div className='error-selected--comment'>{reportResult.customerReportPhotoResults[0].reportAssessment.accepted ? t('report approved') : t('report rejected')}</div>
              </div>
            )}
          </div>
        </div>
      ))}
      { (resultToCheck && resultToCheck.customerReportPhotoResults.length) &&
        <ResultsImageGallery
          activeReportSampleImage={galleryActiveImage}
          resultToCheck={resultToCheck}
          setResultToCheck={setResultToCheck}
        />
      }
      { (customerDailyResultId) && (
        <ShopAllReports
          reportId={reportId}
          resultId={customerDailyResultId}
        />
      ) }
      <ChatComponent
        chatSourceId={resultId}
        chatType={'report-discussion'}
        firebaseDb={firebaseDb}
        source={admin ? 'adminLk' : 'customerLk'}
      />
    </div>
  );
};

export default memo(DiscussionModalContent);
