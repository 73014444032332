// Copyright 2020 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonCommon from 'uiKit/Button';
import NumberWithRoundBackground from 'components/NumberWithRoundBackground';

import './styles.less';

interface ResultsListFooterProps {
  applySelected: () => void;
  applyWithoutVerification: () => void;
  carryOverForVerification: () => void;
  resultsCount: number;
  status: string | undefined;
}

export const ResultsListFooter: React.FC<ResultsListFooterProps> = (props) => {
  const { applySelected, applyWithoutVerification, carryOverForVerification, resultsCount, status } = props;
  const { t } = useTranslation();
  const [selected, results] = (t('selected results') || ' ').toString().split(' ');

  return (
    <div className='results-list-footer'>
      <div className={`results-list-footer--wrapper ${status || ''}`}>
        <div className='results-list-footer--selected'>
          {selected}
          <NumberWithRoundBackground
            isActiveTab
            numb={resultsCount}
          />
          {results}
        </div>
        { status === 'on_customers_review' && (
          <>
            <ButtonCommon
              ghost
              onClick={carryOverForVerification}
              size='large'
              type='primary'
            >
              {(t('carry over for verification') || '').toString()}
            </ButtonCommon>
            <ButtonCommon
              onClick={applyWithoutVerification}
              size='large'
              type='primary'
            >
              {(t('apply without verification') || '').toString()}
            </ButtonCommon>
          </>
        )}
        { status === 'reviewing' && (
          <>
            <div />
            <ButtonCommon
              onClick={applySelected}
              size='large'
              type='primary'
            >
              {(t('apply selected') || '').toString()}
            </ButtonCommon>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(ResultsListFooter);
