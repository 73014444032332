// Copyright 2020 @po-polochkam authors & contributors
import type { FetchCitiesInterface } from 'api/abstractions/dictionary';

import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { CityType } from 'api/abstractions/dictionary';
import { setLoadingCities } from 'store/dictionaryReducer';
import { getCities } from 'api/dictionary';

export interface UseCitiesInterface {
  cities: CityType[];
  fetchCities: (filter: FetchCitiesInterface) => void;
}

export default function useCities (): UseCitiesInterface {
  const dispatch = useAppDispatch();
  const [cities, setCities] = useState<CityType[]>([]);

  const fetchCities = useCallback(async ({ regionAreas, regionId, term }: FetchCitiesInterface) => {
    dispatch(setLoadingCities(true));

    const data = await getCities({ regionAreas, regionId, term }) as CityType[];

    dispatch(setLoadingCities(false));
    setCities(data);
  }, [dispatch]);

  return {
    cities,
    fetchCities
  };
}
