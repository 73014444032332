// Copyright 2020 @po-polochkam authors & contributors

import type { ReportResultType } from 'api/abstractions/result';

import React, { memo, useState } from 'react';
import Magnifier from 'react-magnifier';
import closeModalIcon from 'assets/svg/close-modal-icon.svg';

import ImageThumbnail from '../ResultModalContent/ImageThumbnail';
import './styles.less';

type ResultsImageGalleryProps = {
  resultToCheck: ReportResultType
  setResultToCheck: (result: ReportResultType | undefined) => void;
  activeReportSampleImage: string;
}

const ResultsImageGallery: React.FC<ResultsImageGalleryProps> = (props) => {
  const { activeReportSampleImage, resultToCheck, setResultToCheck } = props;
  const [activeImage, setActiveImage] = useState<string>(resultToCheck.customerReportPhotoResults[0].imagePath);

  return (
    <div
      className='results-image-gallery'
    >
      <div
        className='results-image-gallery--close'
        onClick={setResultToCheck.bind(null, undefined)}
      >
        <img
          alt='closeModalIcon'
          src={closeModalIcon}
        />
      </div>
      <div className='results-image-gallery--content'>
        <div
          className='results-image-gallery--content--sample'
        >
          <Magnifier
            mgHeight={200}
            mgWidth={200}
            src={activeReportSampleImage}
            zoomFactor={2}
          />
        </div>
        <div
          className='results-image-gallery--content--review'
        >
          <Magnifier
            mgHeight={200}
            mgWidth={200}
            src={activeImage}
            zoomFactor={2}
          />
          <div className='thumbnails'>
            { resultToCheck.customerReportPhotoResults.map((reportItem, index) => (
              <ImageThumbnail
                active={reportItem.imagePath === activeImage}
                ind={index + 1}
                key={reportItem.imagePath}
                setActive={setActiveImage}
                src={reportItem.imagePath}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ResultsImageGallery);
