// Copyright 2020 @po-polochkam authors & contributors
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { RangeValue } from 'rc-picker/lib/interface';
import { setResultsDates } from 'store/resutlsReducer';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import 'moment/locale/ru';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/theme';
import clearIcon from 'assets/svg/clearIcon.svg';
import ButtonCommon from 'uiKit/Button';

import './styles.less';

const { RangePicker } = DatePicker;

interface DateFilterProps {
  className?: string;
}

const DateFilter: React.FC<DateFilterProps> = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState<RangeValue<Moment>>();
  const elem = useRef<HTMLElement>(null);
  const resultsDates = useSelector((state: RootState) => state.results.resultsDates);

  const onChange = useCallback((dates: RangeValue<Moment>) => {
    setDateRange(dates);

    if (dates) {
      const date1 = (dates[0] as Moment).format('YYYY-MM-DD');
      const date2 = (dates[1] as Moment).format('YYYY-MM-DD');

      dispatch(setResultsDates([date1, date2]));
    }
  }, [dispatch]);

  const handleClickOutside = useCallback((event: Event) => {
    const el = elem?.current;
    const node: Node = event.target as Node;

    if (!el || el.contains(node)) {
      return;
    }

    setOpen(false);
  }, []);

  const getPopupContainer = useCallback((node: HTMLElement) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    elem.current = node;

    return node;
  }, []);

  const clearRange = useCallback(() => {
    dispatch(setResultsDates(null));
    setDateRange(undefined);
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });

  useEffect(() => {
    if (!resultsDates) {
      setDateRange(undefined);
    } else {
      if (!dateRange) {
        const range = [moment(resultsDates[0]), moment(resultsDates[1])];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setDateRange(range);
      }
    }
  }, [dateRange, resultsDates]);

  return (
    <div
      className='date-filter'
    >
      <RangePicker
        allowClear={false}
        getPopupContainer={getPopupContainer}
        locale={locale}
        onChange={onChange}
        open={open}
        renderExtraFooter={() => (
          <div className='range-picker-footer'>
            <ButtonCommon
              onClick={setOpen.bind(null, false)}
              size='large'
              type='primary'
            >
              {(t('apply') || '').toString()}
            </ButtonCommon>
          </div>
        )}
        separator={''}
        style={{ background: dateRange ? 'white' : theme.lightBackgroundColor, border: dateRange ? theme.bordersSecondary : 'none' }}
        value={dateRange ? [moment(dateRange[0], 'YYYY-MM-DD'), moment(dateRange[1], 'YYYY-MM-DD')] : null}
      />
      <div
        className={`date-filter--button ${dateRange ? 'active' : ''}`}
      >
        <span onClick={setOpen.bind(null, !open)}>{t('date')}</span>
        <img
          alt='clear-icon'
          className='date-filter--button--clear'
          onClick={clearRange}
          src={clearIcon}
        />
      </div>
    </div>
  );
};

export default DateFilter;
