// Copyright 2020 @po-polochkam authors & contributors

import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'antd/lib/dropdown';
import { useAppDispatch, RootState } from 'store/store';
import type { CustomerType } from 'api/abstractions/dictionary';
import { useSelector } from 'react-redux';
import { setResultsCustomers } from 'store/resutlsReducer';
import useCustomers from 'hooks/useCustomers';
import ButtonCommon from 'uiKit/Button';

import CheckboxCommon from 'uiKit/Checkbox/CheckboxCommon';
import clearIcon from 'assets/svg/clearIcon.svg';
import './styles.less';

const CustomerFilter: React.FC = () => {
  const [openedList, setOpenedList] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { customers, fetchCustomers } = useCustomers();
  const selected = useSelector((state: RootState) => state.results.resultsCustomers) as {[key: string]: CustomerType};
  const elem = useRef<HTMLDivElement>(null);

  const clearSelected = useCallback(() => {
    dispatch(setResultsCustomers({}));
  }, [dispatch]);

  const handleClickOutside = useCallback((event: Event) => {
    const el = elem?.current;

    if (!el ||
      el.contains((event?.target as Node) || null) ||
      (event.target as HTMLElement).classList.contains('ant-dropdown-trigger')
    ) {
      return;
    }

    setOpenedList(false);
  }, []);

  const onSelectItem = useCallback((item: CustomerType) => {
    let newState = { ...selected };

    if (newState[item.id]) {
      delete newState[item.id];
    } else {
      newState = { ...selected, [item.id]: item };
    }

    dispatch(setResultsCustomers(newState));
  }, [dispatch, selected]);

  const onDropdownButtonClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event && event.target) {
      if ((event.target as HTMLElement).classList.contains('customer-filter--button--clear')) {
        return;
      }
    }

    setOpenedList(!openedList);
  }, [openedList]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });

  const menu = useMemo(() => {
    return (
      <div
        className='customer-filter--block'
        onClick={(e) => e.stopPropagation()}
        ref={elem}
      >
        <div className='network-filter--list'>
          { customers.map((item: CustomerType) => (
            <div
              className={'customer-filter--list--item'}
              key={item.id}
            >
              <CheckboxCommon
                checked={!!(selected && selected[item.id])}
                name={`${item.id}`}
                onChange={onSelectItem.bind(this, item)}
              >
                <span>{item.name}</span>
              </CheckboxCommon>
            </div>
          ))}
        </div>
        <div className='filter-footer'>
          <ButtonCommon
            onClick={setOpenedList.bind(null, false)}
            size='large'
            type='primary'
          >
            {(t('apply') || '').toString()}
          </ButtonCommon>
        </div>
      </div>
    );
  }, [customers, onSelectItem, selected, t]);

  return (
    <div
      className='customer-filter'
      onClick={onDropdownButtonClick}
    >
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={openedList}
      >
        <div
          className={`customer-filter--button ${Object.keys(selected).length ? 'active' : ''}`}
        >
          {t('customer')}
          <img
            alt='clear-icon'
            className='customer-filter--button--clear'
            onClick={clearSelected}
            src={clearIcon}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default CustomerFilter;
