// Copyright 2020 @po-polochkam authors & contributors
import type { ClaimResponseType } from 'api/abstractions/dictionary';

import { yandex } from 'app-config';
import moment from 'moment';
import ButtonCommon from 'uiKit/Button';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportResultGroupEnriched } from 'api/abstractions/result';
import useClaimResponses from 'hooks/useClaimResponses';
import Select from 'antd/lib/select';
import dropdownArrow from 'assets/svg/dropdownArrow.svg';
import './styles.less';

const { Option } = Select;

interface Props {
  address: string;
  selectedResultGroup: ReportResultGroupEnriched | undefined;
  toggleResultModal?: (isOpen: boolean) => void;
  activeImage: string;
}

interface Option {
  label: string;
  value: string;
}

const AdminModalHeader: React.FC<Props> = (props: Props) => {
  const { address, selectedResultGroup, toggleResultModal } = props;
  const { acceptClaimResponse, claimResponses, declineClaimResponse, fetchClaimResponses } = useClaimResponses();
  const [claimResponse, setClaimResponse] = useState<string>();
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const fillOptions = useCallback(() => {
    setOptions([...claimResponses.map((resp: ClaimResponseType) => ({ label: resp.text, value: resp.id }))]);
  }, [claimResponses]);

  const setValue = useCallback((value: string) => {
    setClaimResponse(value);
  }, []);

  const accept = useCallback(async () => {
    if (selectedResultGroup) {
      await acceptClaimResponse(selectedResultGroup.customerDailyResultId, selectedResultGroup.id);
      toggleResultModal && toggleResultModal(false);
    }
  }, [acceptClaimResponse, selectedResultGroup, toggleResultModal]);

  const decline = useCallback(async () => {
    if (selectedResultGroup && claimResponse) {
      await declineClaimResponse(selectedResultGroup.customerDailyResultId, selectedResultGroup.id, claimResponse);
      toggleResultModal && toggleResultModal(false);
    }
  }, [declineClaimResponse, claimResponse, selectedResultGroup, toggleResultModal]);

  useEffect(() => {
    if (claimResponses?.length > options.length) {
      fillOptions();
    }
  }, [claimResponses, fillOptions, options]);

  useEffect(() => {
    fetchClaimResponses();
  }, [fetchClaimResponses]);

  return (
    <div className='discussion-modal-content--header admin'>
      <div className='name'>
        <div className='customer-name'>{selectedResultGroup?.customerDailyResult.customerName}</div>
        <div className='network-name'>
          <img
            alt={selectedResultGroup?.customerDailyResult?.tradeNetwork?.name}
            src={selectedResultGroup?.customerDailyResult?.tradeNetwork?.icon}
          />
          {selectedResultGroup?.customerDailyResult?.tradeNetwork?.name}
        </div>
        <a
          className='address'
          href={`${yandex}${address}`}
          rel='noreferrer nooperer'
          target='_blank'
        >
          {address}
        </a>
      </div>
      <div className='description'>
        <span className='date'>{moment(selectedResultGroup?.customerDailyResult?.date, 'YYYY-MM-DD').format('DD MMMM, YYYY г.')}</span>
        <span className='category'>{selectedResultGroup?.productCategory.name}</span>
      </div>
      { selectedResultGroup?.customerClaimResponse.id && (
        <div className='decision-block-claim'>
          <div className='claim'>
            {selectedResultGroup?.customerClaimResponse.text}
          </div>
          <div className='claim-status'>
            {t('waiting for customer response')}
          </div>
        </div>
      )}
      { !selectedResultGroup?.customerClaimResponse.id && (
        <div className='two-decision-blocks'>
          <div className='decision-block'>
            <div className='error-selector'>
              <Select
                dropdownClassName='error-selector'
                onChange={setValue}
                placeholder={t('select an option')}
                style={{ width: '100%' }}
                suffixIcon={
                  <img
                    alt='add item'
                    src={dropdownArrow}
                  />
                }
                value={claimResponse}
              >
                { options.map((option) => (
                  <Option
                    key={option.value}
                    label={option.label}
                    value={option.value}
                  >
                    <div className='option-label-item'>
                      {option.label}
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            <ButtonCommon
              disabled={!claimResponse}
              onClick={decline}
              size='large'
              type='primary'
            >
              {(t('customers error') || '').toString()}
            </ButtonCommon>
          </div>
          <div className='decision-block'>
            <div className='decision-text'>
              {t('the dispute will be automatically closed')}
            </div>
            <ButtonCommon
              onClick={accept}
              size='large'
              type='primary'
            >
              {(t('service error') || '').toString()}
            </ButtonCommon>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(AdminModalHeader);
