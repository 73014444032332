// Copyright 2020 @po-polochkam authors & contributors
import type { Filter, ResultType } from 'api/abstractions/result';
import { RootState, useAppDispatch } from 'store/store';

import { useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { getDailyResults } from 'api/results';

import { receiveResults, reset, setLoadingResults } from 'store/resutlsReducer';
import useResultGroupsItems from './useResultGroupsItems';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DiscussionFilter } from 'api/abstractions/result';

interface UseResultsInterface {
  getItems: (filter: Filter, page: number) => void;
  initialLoad: (mode: 'full' | 'light') => void;
  mergeFilter: (filter: Filter) => Filter;
}

export function useResultsItems (): UseResultsInterface {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { getResultGroups } = useResultGroupsItems();
  const resultsDates = useSelector((state: RootState) => state.results.resultsDates);
  const resultsNetworks = useSelector((state: RootState) => state.results.resultsNetworks);
  const resultsAreas = useSelector((state: RootState) => state.results.resultsAreas);
  const resultsRegions = useSelector((state: RootState) => state.results.resultsRegions);
  const resultsCities = useSelector((state: RootState) => state.results.resultsCities);
  const searchString = useSelector((state: RootState) => state.results.resultsSearch);
  const page = useSelector((state: RootState) => state.results.page);
  const perPage = useSelector((state: RootState) => state.results.perPage);
  const localPage = useRef<number>(1);

  const getResults = useCallback(async (currentPage, filter) => {
    const data: { error?: any } = await getDailyResults(currentPage, filter, perPage);

    if (data.error) {
      dispatch(setLoadingResults({ loading: false }));

      return {
        count: 0,
        items: []
      };
    }

    const count = get(data, ['count'], 0);
    const items = get(data, ['items'], []) as Array<ResultType>;

    return { count, items };
  }, [dispatch, perPage]);

  const getItems = useCallback(async (filter: Filter, page) => {
    dispatch(setLoadingResults({ loading: true }));
    const { count, items } = await getResults(page, filter);

    dispatch(setLoadingResults({ loading: false }));
    dispatch(receiveResults({ count, filter, items, page }));
  }, [dispatch, getResults]);

  const mergeAddressFilter = useCallback((filter) => {
    const fullFilter: Filter = { ...filter };

    if (resultsRegions) {
      fullFilter.regions = resultsRegions;
    }

    if (resultsCities) {
      fullFilter.cities = resultsCities;
    }

    if (resultsAreas && resultsAreas.length) {
      fullFilter.regionAreas = resultsAreas.map((item) => item.id);
    }

    return fullFilter;
  }, [resultsAreas, resultsCities, resultsRegions]);

  const mergeFilter = useCallback((filter: Filter) => {
    const fullFilter: Filter = mergeAddressFilter(filter);

    if (searchString) {
      fullFilter.term = searchString;
    }

    if (resultsDates && resultsDates.length) {
      fullFilter.dateFrom = moment(resultsDates[0]).format('YYYY-MM-DD');
      fullFilter.dateTo = moment(resultsDates[1]).format('YYYY-MM-DD');
    }

    if (resultsNetworks && Object.keys(resultsNetworks).length) {
      fullFilter.tradeNetworks = Object.keys(resultsNetworks);
    }

    return fullFilter;
  }, [mergeAddressFilter, resultsDates, resultsNetworks, searchString]);

  const mergeDateFilter = useCallback((filter: DiscussionFilter) => {
    const fullFilter: DiscussionFilter = { ...filter };

    if (resultsDates && resultsDates.length) {
      fullFilter.dateFrom = moment(resultsDates[0]).format('YYYY-MM-DD');
      fullFilter.dateTo = moment(resultsDates[1]).format('YYYY-MM-DD');
    } else {
      fullFilter.dateFrom = moment().subtract(62, 'days').format('YYYY-MM-DD');
      fullFilter.dateTo = moment().format('YYYY-MM-DD');
    }

    return fullFilter;
  }, [resultsDates]);

  const mergeNetworkFilter = useCallback((filter: DiscussionFilter) => {
    const fullFilter: DiscussionFilter = { ...filter };

    if (resultsNetworks && Object.keys(resultsNetworks).length) {
      fullFilter.tradeNetworks = Object.keys(resultsNetworks);
    }

    return fullFilter;
  }, [resultsNetworks]);

  const mergeDiscussionFilter = useCallback((filter: DiscussionFilter) => {
    return mergeNetworkFilter(mergeDateFilter(mergeAddressFilter({ ...filter })));
  }, [mergeAddressFilter, mergeDateFilter, mergeNetworkFilter]);

  const initialLoad = useCallback(async (mode) => {
    let onCustomerReviewPage = 1;
    let reviewingPage = 1;
    let inDiscussionPage = 1;

    if (location.pathname.includes('on-review')) {
      onCustomerReviewPage = page;
    }

    if (location.pathname.includes('checking')) {
      reviewingPage = page;
    }

    if (location.pathname.includes('in-discussion')) {
      inDiscussionPage = page;
    }

    if (mode === 'light') {
      await getItems(mergeFilter({ status: 'on_customers_review' }), onCustomerReviewPage);
      await getItems(mergeFilter({ status: 'reviewing' }), reviewingPage);
      await getResultGroups(mergeDiscussionFilter({ approvalStatuses: ['new', 'accepted', 'declined'], statuses: ['active', 'dispute', 'closed', 'revoked'] }), inDiscussionPage);
    } else {
      dispatch(reset());
      await getItems({ status: 'on_customers_review' }, onCustomerReviewPage);
      await getItems({ status: 'reviewing' }, reviewingPage);
      await getResultGroups({ approvalStatuses: ['new', 'accepted', 'declined'], dateFrom: moment().subtract(62, 'days').format('YYYY-MM-DD'), dateTo: moment().format('YYYY-MM-DD'), statuses: ['active', 'dispute', 'closed', 'revoked'] }, inDiscussionPage);
    }

    localPage.current = page;
  }, [dispatch, getItems, getResultGroups, location, mergeDiscussionFilter, mergeFilter, page]);

  return {
    getItems,
    initialLoad,
    mergeFilter
  };
}

export default useResultsItems;
