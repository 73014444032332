// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonCommon from 'uiKit/Button';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import LayoutContent from 'components/ResultModalContent/LayoutContent';
import useResult from 'hooks/useResult';

interface ShopAllReportsProps {
  reportId: string;
  resultId: string;
}

const ShopAllReports: React.FC<ShopAllReportsProps> = ({ reportId, resultId }) => {
  const { t } = useTranslation();
  const [showAll, setSowAll] = useState<boolean>(false);
  const { fetchResult, selectedResult } = useResult(resultId);

  const toggle = useCallback(() => {
    setSowAll(!showAll);
  }, [showAll]);

  useEffect(() => {
    void fetchResult();
  }, [fetchResult, resultId]);

  return (
    <div className='discussion-list'>
      <ButtonCommon
        disabled={(selectedResult?.reportResults.length || 0) < 2}
        icon={ (showAll) ? <UpOutlined /> : <DownOutlined /> }
        onClick={toggle}
        size='large'
        type='link'
      >
        {(t('show shop all reports') || '').toString()}
      </ButtonCommon>
      { (showAll) && (
        <LayoutContent
          mayEdit={false}
          reportId={reportId}
          resultId={resultId}
        />
      )}
      { (!showAll) && (
        <div style={ { height: 20 }}>

        </div>
      )}
    </div>
  );
};

export default memo(ShopAllReports);
