// Copyright 2020 @po-polochkam authors & contributors
import type { Filter } from 'api/abstractions/result';
import { RootState, useAppDispatch } from 'store/store';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useResultsItems from './useResultsItems';

import { reset, ResultsMapInterface, setLoadingResults, setPage } from 'store/resutlsReducer';
import { applyWithoutVerification, doneReviewing, startReviewing } from 'api/results';

interface UseResultsInterface {
  getItems: (filter: Filter, page: number) => void;
  initialLoad: (mode: 'full' | 'light') => void;
  loadingResults: boolean;
  onApplySelected: (all: boolean, ids: string[], filterStatus: string) => void,
  onApplyWithoutVerification: (all: boolean, ids: string[], filterStatus: string) => void,
  page: number;
  resetResults: () => void;
  results: ResultsMapInterface;
  resultsCount: { [key: string]: number };
  resultsDates: [string, string] | null;
  startReviewingItems: (all: boolean, ids: string[], filterStatus: string) => void;
}

export const resultsPerPage = 10;

export function useResults (): UseResultsInterface {
  const dispatch = useAppDispatch();
  const { getItems, initialLoad, mergeFilter } = useResultsItems();
  const loadingResults = useSelector((state: RootState) => state.results.loadingResults);
  const resultsCount = useSelector((state: RootState) => state.results.count);
  const results = useSelector((state: RootState) => state.results.results);
  const resultsDates = useSelector((state: RootState) => state.results.resultsDates);
  const page = useSelector((state: RootState) => state.results.page);

  const resetResults = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const startReviewingItems = useCallback(async (all: boolean, ids: string[], filterStatus: string) => {
    dispatch(setLoadingResults({ loading: true }));
    const fullFilter = mergeFilter({ status: filterStatus });
    const data: { error?: any } = await startReviewing(fullFilter, all ? [] : ids);

    dispatch(setLoadingResults({ loading: false }));

    if (data.error) {
      return;
    }

    dispatch(setPage(1));

    void initialLoad('light');
  }, [dispatch, initialLoad, mergeFilter]);

  const onApplyWithoutVerification = useCallback(async (all: boolean, ids: string[], filterStatus: string) => {
    dispatch(setLoadingResults({ loading: true }));
    const fullFilter = mergeFilter({ status: filterStatus });
    // добавляем фильтр в запрос
    const data: { error?: any } = await applyWithoutVerification(fullFilter, all ? [] : ids);

    dispatch(setLoadingResults({ loading: false }));

    if (data.error) {
      return;
    }

    dispatch(setPage(1));

    void initialLoad('light');
  }, [dispatch, initialLoad, mergeFilter]);

  const onApplySelected = useCallback(async (all: boolean, ids: string[], filterStatus: string) => {
    dispatch(setLoadingResults({ loading: true }));
    const fullFilter = mergeFilter({ status: filterStatus });

    const data: { error?: any } = await doneReviewing(fullFilter, all ? [] : ids);

    dispatch(setLoadingResults({ loading: false }));

    if (data.error) {
      return;
    }

    dispatch(setPage(1));

    void initialLoad('light');
  }, [dispatch, initialLoad, mergeFilter]);

  return {
    getItems,
    initialLoad,
    loadingResults,
    onApplySelected,
    onApplyWithoutVerification,
    page,
    resetResults,
    results,
    resultsCount,
    resultsDates,
    startReviewingItems
  };
}

export default useResults;
