// Copyright 2021 @po-polochkam authors & contributors
import type { ChatMessage, ChatMessageFile } from 'components/ChatComponent/firebaseStore/entities/chatMessages';
import type { ChatSource, ChatType } from 'components/ChatComponent/types';
import firebase from 'firebase';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { uploadFile } from 'api/user';

export interface ChatFirebaseInterface {
  chatMessages: { [chatMessageId: string]: ChatMessage };
  fetchMessagesById: () => void;
  loading: boolean;
  markAsRead: (messageId: string) => void;
  sendMessage: ({ files, message }: { message?: string, files: File[] }) => Promise<any>;
}

export default function useChatFirebase (chatSourceId: string, chatType: ChatType, firebaseDb: firebase.database.Database | undefined, source: ChatSource, userId: string): ChatFirebaseInterface {
  const [chatMessages, setChatMessages] = useState<{ [chatMessageId: string]: ChatMessage }>({});
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMessagesById = useCallback(() => {
    if (!firebaseDb) {
      return;
    }

    const starCountRef: firebase.database.Reference = firebaseDb.ref(`resultsMessages/${chatSourceId}`);

    starCountRef.on('value', (snapshot) => {
      if (snapshot.val()) {
        setChatMessages(snapshot.val());
      }
    });
  }, [chatSourceId, firebaseDb]);

  const markAsRead = useCallback((messageId: string) => {
    if (!firebaseDb) {
      return;
    }

    const messageRef = `resultsMessages/${chatSourceId}/${messageId}`;
    const dbRef = firebaseDb.ref();

    dbRef.child(messageRef).get().then(async (snapshot) => {
      if (snapshot.exists()) {
        await firebaseDb.ref(messageRef).set({
          ...snapshot.val(),
          readAt: moment().utc().format('YYYY-MM-DD-HH-mm-ss')
        });
      } else {
        console.log('No data available', `${messageRef}`);
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [chatSourceId, firebaseDb]);

  const setLastMessage = useCallback((lastMessage: ChatMessage): Promise<any> | null => {
    if (!firebaseDb) {
      return null;
    }

    const messageRef = `chats/${chatSourceId}`;
    const dbRef = firebaseDb.ref();
    const postListRef = firebaseDb.ref('chats');

    return dbRef.child(messageRef).get().then(async (snapshot: firebase.database.DataSnapshot) => {
      if (snapshot.exists()) {
        await firebaseDb.ref(messageRef).set({
          ...snapshot.val(),
          lastMessage
        });
      } else {
        console.log('No data available', `chats/${chatSourceId}`);
        const newPostRef = postListRef.push();

        await newPostRef.set({
          lastMessage,
          name: `userId: ${lastMessage.userId}`,
          state: 'active',
          type: chatType
        });
      }
    }).catch((error: Error) => {
      console.error(error);

      return error;
    });
  }, [chatSourceId, chatType, firebaseDb]);

  // @todo - uploading files logic
  //  application/octet-stream media type
  // "image/jpeg", "image/png", "application/pdf", "audio/mp3", "video/mp4"
  const onSaveFile = useCallback(async (file: Blob): Promise<any> => {
    const formData = new FormData();

    formData.append('file', file);

    const blob = new Blob([file], { type: file.type });

    const result: { id: string, path: string } = await uploadFile(userId, blob, file.type);

    return result.path;
  }, [userId]);

  // если таблицы такой нет, то она автоматически создается
  const sendMessage = useCallback(async ({ files, message }: { files: File[], message?: string }) => {
    if (!firebaseDb) {
      return;
    }

    setLoading(true);
    const messageRef = `resultsMessages/${chatSourceId}`;
    const postListRef = firebaseDb.ref(messageRef);

    if (files?.length) {
      const filesResults: ChatMessageFile[] = [];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for await (const file of files) {
        const path = await onSaveFile(file);

        if (path) {
          filesResults.push({
            length: file.size,
            name: file.name,
            path
          });
        }
      }

      if (filesResults.length) {
        const newPostRef = postListRef.push();
        const newMessage: ChatMessage = {
          dateTime: moment().utc().format('YYYY-MM-DD-HH-mm-ss'),
          files: filesResults,
          isSend: true,
          readAt: null,
          source: source,
          type: 'file',
          userId: userId
        };

        await newPostRef.set(newMessage);
        await setLastMessage(newMessage);
      }
    }

    if (message?.length) {
      const newPostRef = postListRef.push();
      const newMessage: ChatMessage = {
        dateTime: moment().utc().format('YYYY-MM-DD-HH-mm-ss'),
        files: [],
        isSend: true,
        message,
        readAt: null,
        source: source,
        type: 'text',
        userId: userId
      };

      await newPostRef.set(newMessage);
      await setLastMessage(newMessage);
    }

    setLoading(false);
  }, [source, onSaveFile, chatSourceId, setLastMessage, firebaseDb, userId]);

  return {
    chatMessages,
    fetchMessagesById,
    loading,
    markAsRead,
    sendMessage
  };
}
