// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, ReactNode } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { Route, Switch } from 'react-router-dom';
import './styles.less';

interface TabsCommonProps {
  destroyInactiveTabPane?: boolean;
  exact?: boolean;
  onChangeTab: (item: any, key: string) => void;
  tabsPath: string;
  tabs: Array<{ id: string, tabHeader: string | ReactNode, tabContent?: string | ReactNode }>
}

const TabsCommon: React.FC<TabsCommonProps> = (props) => {
  const { destroyInactiveTabPane, exact, onChangeTab, tabs, tabsPath } = props;

  return (
    <Route
      exact={exact}
      path={`${tabsPath}:tabId`}
      render={
        ({ history, match }: { match: { params: { tabId: string } }, history: { push: (param: string) => void } }) => (
          <div className='tab-container'>
            <Switch>
              <>
                <Tabs
                  defaultActiveKey={match.params.tabId}
                  destroyInactiveTabPane={destroyInactiveTabPane}
                  onChange={onChangeTab.bind(null, { history, tabsPath })}
                >
                  {tabs.map((tabItem) => (
                    <TabPane
                      key={tabItem.id}
                      tab={tabItem.tabHeader}
                    >
                      {tabItem.tabContent}
                    </TabPane>
                  ))}
                </Tabs>
              </>
            </Switch>
          </div>
        )}
    />
  );
};

export default memo(TabsCommon);
