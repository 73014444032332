// Copyright 2020 @po-polochkam authors & contributors
import { homePage } from 'app-config';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { logout, isAdmin, isCustomer } from 'api/manageToken';

import MenuRequestsIcon from 'components/SVG/MenuRequestsIcon';
import ResultVerificationIcon from 'components/SVG/ResultVerificationIcon';
import FinishSession from 'components/SVG/FinishSessionIcon';
import logo from 'assets/svg/logo.svg';
import { useAppDispatch } from 'store/store';
import { fullReset } from 'store/resutlsReducer';

import './styles.less';
import packageJson from '../../../package.json';

const { Sider } = Layout;
const { version } = packageJson;

interface SidebarMenuProps {
  collapsed: boolean;
}

const SidebarMenu:React.FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
  const { collapsed } = props;
  const { t } = useTranslation();
  const admin = isAdmin();
  const customer = isCustomer();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const goToHomePage = useCallback(() => {
    if (admin) {
      history.push('/home/discussion-catalog');
    } else if (customer) {
      history.push('/home/results-verification/on-review');
    }
  }, [admin, customer, history]);

  const onLogout = useCallback(() => {
    dispatch(fullReset());
    logout();
  }, [dispatch]);

  return (
    <Sider
      collapsed={collapsed}
      collapsible
      trigger={null}
      width={241}
    >
      <div className='logo-img'>
        <a onClick={goToHomePage}>
          <img
            alt={'po polochkam'}
            src={logo}
          />
          <span>
            {t('Po polochkam')}
            <small>{t('version')} {version}</small>
          </span>
        </a>
      </div>
      <div className='top-sidebar-menu'>
        <Menu
          defaultSelectedKeys={['results-verification']}
          mode='inline'
          theme='dark'
        >
          { admin && (
            <>
              <Menu.Item
                icon={<MenuRequestsIcon />}
                key='requests'
              >
                <Link to='/home/discussion-catalog'>{(t('discussion catalog') || '').toString()}</Link>
              </Menu.Item>
            </>
          )}
          { customer && (
            <>
              {/* <Menu.Item
                icon={<MenuRequestsIcon />}
                key='requests'
              >
                <Link to='/home/requests'>{(t('requests') || '').toString()}</Link>
              </Menu.Item> */}
              <Menu.Item
                icon={<ResultVerificationIcon />}
                key='results-verification'
              >
                <Link to={homePage}>{(t('results verification') || '').toString()}</Link>
              </Menu.Item>
              {/* <Menu.Item
                icon={<CalculationIcon />}
                key='calculations'
              >
                <Link to='/home/calculations'>{(t('calculations') || '').toString()}</Link>
              </Menu.Item> */}
              {/* <Menu.Item
                icon={<AnalyticsIcon />}
                key='analytics'
              >
                <Link to='/home/analytics'>{(t('analytics') || '').toString()}</Link>
              </Menu.Item> */}
            </>
          )}
        </Menu>
      </div>
      <div className='bottom-sidebar-menu'>
        <Menu
          defaultSelectedKeys={[]}
          mode='inline'
          theme='dark'
        >
          {/* Footer menu */}
          {/* <Menu.Item
            icon={<FaqIcon />}
            key='faq'
          >
            <Link to='/home/faq'>{(t('faq') || '').toString()}</Link>
          </Menu.Item>
          <Menu.Item
            icon={<FaqIcon />}
            key='support'
          >
            <Link to='/home/support'>{(t('support') || '').toString()}</Link>
          </Menu.Item> */}
          <Menu.Item
            data-testid='logout'
            icon={<FinishSession />}
            key='finish_session'
          >
            <a onClick={onLogout}>{(t('finish session') || '').toString()}</a>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};

export default SidebarMenu;
