// Copyright 2020 @po-polochkam authors & contributors
import { ReportResultGroup, ResultGroupMap } from 'api/abstractions/result';
import { User } from 'api/abstractions/user';
import { getUser } from 'api/manageToken';

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { ConfigProvider, Pagination, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ru';

import ModalCommon from 'uiKit/Modal';
import DiscussionModalContent from 'components/DiscussionModalContent';
import useReportResultGroups from 'hooks/useReportResultGroups';
import AdminDiscussionListFilters from 'components/AdminDiscussionListFilters';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { setLoadingResultGroups, setPage, setPerPage, setViewedDiscussionResults } from 'store/resutlsReducer';

import './styles.less';
import useFirebaseDb from '../ChatComponent/hooks/useFirebaseDb';
import toggleIconMinus from 'assets/svg/toggle-icon-minus.svg';
import toggleIconPlus from 'assets/svg/toggle-icon-plus.svg';
import DiscussListItem from '../DiscussListItem';
import ruRU from 'antd/lib/locale/ru_RU';

moment.locale('ru');

const DiscussionAdminList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [networks, setNetworks] = useState<{[networkId: string]: { icon: string, name: string }}>({});
  const [customers, setCustomers] = useState<{[customerId: string]: string}>({});
  const { groupsCount, initialLoad, resultGroups } = useReportResultGroups();
  const [resultsMappedByDate, setResultsMappedByDate] = useState<ResultGroupMap>({});
  const viewedDiscussionResults = useSelector((state: RootState) => state.results.viewedDiscussionResults);
  const loadingResultGroups = useSelector((state: RootState) => state.results.loadingResultGroups);
  const page = useSelector((state: RootState) => state.results.page);
  const perPage = useSelector((state: RootState) => state.results.perPage);
  const [closedItems, setClosedItems] = useState<{ [itemId: string]: boolean }>({});
  const history = useHistory();
  const location = useLocation();
  const user: User = getUser() as User;
  const { firebaseDb } = useFirebaseDb(user);

  const toggleItem = useCallback((itemId: string) => {
    setClosedItems((prevState) => {
      return {
        ...prevState,
        [itemId]: !prevState[itemId]
      };
    });
  }, []);

  /* Здесь мы берем на входе плоский список (постранично) - resultGroups[page] и превращаем его в маппинг
  по 4 ключам - date, customerId, tradeNetworkId, resultGroupId, для того, чтобы отрисовать дизайн, состоящий
  из вложенных один в другой блоков по дате, заказчику, сети и группе.
   */
  const mapResults = useCallback(() => {
    if (Object.keys(resultGroups)?.length > 0) {
      const resultsMap: ResultGroupMap = {};

      resultGroups.forEach((resultItem: ReportResultGroup) => {
        const newResultItem: ReportResultGroup = { ...resultItem };

        // верхний блок - по дате. Создаем, если еще нет блока по такой дате.
        if (!resultsMap[newResultItem.customerDailyResult.date]) {
          resultsMap[newResultItem.customerDailyResult.date] = {};
        }

        // в блок по дате вкладываем блок по заказчику. Создаем, если нет.
        if (!resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.customerId]) {
          resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.customerId] = {};
          setCustomers((prevState) => {
            if (!prevState[newResultItem.customerDailyResult.customerId]) {
              return {
                ...prevState,
                [newResultItem.customerDailyResult.customerId]: newResultItem.customerDailyResult.customerName
              };
            } else {
              return prevState;
            }
          });
        }

        // в блок по заказчику вкладываем блок по сети. Создаем, если нет.
        if (!resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.customerId][newResultItem.customerDailyResult.shop.id]) {
          resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.customerId][newResultItem.customerDailyResult.shop.id] = {};
          setNetworks((prevState) => {
            if (!prevState[newResultItem.customerDailyResult.shop.id]) {
              // формируем строку, состоящую из иконки, сети и адреса.
              const tradeNetworkName = newResultItem.customerDailyResult.tradeNetwork.name;
              const cityName: string = newResultItem?.customerDailyResult?.shopAddress?.cityName || '';
              const regionAreaName: string = newResultItem?.customerDailyResult?.shopAddress?.regionAreaName || '';
              const streetName = newResultItem?.customerDailyResult?.shopAddress?.street || '';
              const address = [tradeNetworkName, streetName, cityName, regionAreaName].filter((item: string) => item.length > 0).join(', ');

              return {
                ...prevState,
                [newResultItem.customerDailyResult.shop.id]: {
                  icon: newResultItem.customerDailyResult.tradeNetwork.icon,
                  name: address
                }
              };
            } else {
              return prevState;
            }
          });
        }

        resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.customerId][newResultItem.customerDailyResult.shop.id][newResultItem.id] = newResultItem;
      });

      setResultsMappedByDate(resultsMap);
    } else {
      setResultsMappedByDate({});
    }
  }, [resultGroups]);

  const closeModal = useCallback(() => {
    if (location.pathname.includes('discussion-modal')) {
      const indexFrom = location.pathname.indexOf('discussion-modal');
      const substr = location.pathname.slice(indexFrom, location.pathname.length);

      history.push(location.pathname.replace(substr, ''));
    }
  }, [history, location]);

  const toggleResultModal = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      closeModal();
      initialLoad('light');
    }
  }, [closeModal, initialLoad]);

  const onItemClick = useCallback((resultId: string) => {
    dispatch(setViewedDiscussionResults({ ...viewedDiscussionResults, [`${user.id}-${resultId}`]: true }));
    history.push(`/home/discussion-catalog/discussion-modal/${resultId}`);
  }, [dispatch, history, viewedDiscussionResults, user]);

  const resultGroupsItems = useCallback((item: { [resultGroupId: string]: ReportResultGroup }) => {
    return Object.values(item).sort((a, b) => a.productCategory.name.localeCompare(b.productCategory.name));
  }, []);

  const onShowSizeChange = useCallback((current: number, pageSize: number) => {
    dispatch(setPerPage(pageSize));
  }, [dispatch]);

  const onChangePage = useCallback((pageNumber: number) => {
    dispatch(setLoadingResultGroups({ loading: true }));
    dispatch(setPage(pageNumber));
  }, [dispatch]);

  useEffect(() => {
    mapResults();
  }, [mapResults]);

  useEffect(() => {
    dispatch(setLoadingResultGroups({ loading: true }));
  }, [dispatch]);

  return (
    <div className='discussion-admin-list'>
      <AdminDiscussionListFilters
        count={groupsCount}
        resultsMappedByDate={resultsMappedByDate}
      />
      { Object.keys(resultGroups).length > 0 && (
        <ConfigProvider locale={ruRU}>
          <Pagination
            current={page}
            defaultPageSize={perPage}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showQuickJumper
            showSizeChanger
            showTitle={false}
            total={groupsCount || 0}
          />
        </ConfigProvider>
      )}
      <div className='list-gradient-block' />
      <div className='discussion-admin-list--body'>
        { loadingResultGroups && (
          <div className='mu-spin-container'>
            <Spin
              key={'results-list'}
              size='large'
              tip={t('loading')}
            />
          </div>
        )}
        { !loadingResultGroups && Object.keys(resultsMappedByDate).map((dateKey: string) => (
          <div
            className='discussion-admin-list--item'
            key={dateKey}
          >
            <div className='discussion-admin-list--date'>
              {moment(dateKey, 'YYYY-MM-DD').format('DD MMMM YYYY')}
            </div>
            { Object.keys(resultsMappedByDate[dateKey]).map((customerId: string) => (
              <div
                className='customer-block'
                key={customerId}
              >
                <div className='customer-block--name'>{customers[customerId]}</div>
                <div
                  className='customer-block--toggle'
                  onClick={toggleItem.bind(null, `${customerId}-${dateKey}`)}
                >
                  <img
                    alt='toggle'
                    src={!closedItems[`${customerId}-${dateKey}`] ? toggleIconMinus : toggleIconPlus}
                  />
                </div>
                { !closedItems[`${customerId}-${dateKey}`] && Object.keys(resultsMappedByDate[dateKey][customerId]).map((shopId: string) => (
                  <div
                    className='list-items-block'
                    key={shopId}
                  >
                    <div className='list-item-header'>
                      <div className='list-item-header--address'>
                        <img
                          alt={networks[shopId].name}
                          src={networks[shopId].icon}
                        />
                        <span>{networks[shopId].name}</span>
                      </div>
                    </div>
                    <div className='list-items-body'>
                      { resultGroupsItems(resultsMappedByDate[dateKey][customerId][shopId]).map((result: ReportResultGroup) => (
                        <div
                          className='list-item'
                          key={result.id}
                        >
                          <DiscussListItem
                            admin
                            category={result.productCategory}
                            firebaseDb={firebaseDb}
                            /* я уже просматривал */
                            gray={viewedDiscussionResults[`${user?.id}-${result.id}`]}
                            id={result.id}
                            isOpen={(result.claimApproval === 'no_claim' || result.claimApproval === 'new') || (result.claimApproval === 'declined' && result.claimStatus === 'active')}
                            mistakes={result.mistakes}
                            onItemClick={onItemClick}
                            user={user}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
      { Object.keys(resultGroups).length > 0 && (
        <ConfigProvider locale={ruRU}>
          <Pagination
            current={page}
            defaultPageSize={perPage}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showQuickJumper
            showSizeChanger
            showTitle={false}
            total={groupsCount || 0}
          />
        </ConfigProvider>
      )}
      <Switch>
        <Route
          key='discussion-modal'
          path='*/discussion-modal/:resultId'
        >
          <ModalCommon
            modalContent={
              <DiscussionModalContent
                admin
                firebaseDb={firebaseDb}
                toggleResultModal={toggleResultModal}
              />
            }
            setVisible={closeModal}
            visible={true}
            width={1014}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default memo(DiscussionAdminList);
