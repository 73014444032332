// Copyright 2020 @po-polochkam authors & contributors
import type { DiscussionFilter, ReportResultGroup } from 'api/abstractions/result';
import { RootState, useAppDispatch } from 'store/store';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import { getReportResultGroups } from 'api/results';

import { receiveResultGroups, setLoadingResultGroups, reset } from 'store/resutlsReducer';
import { useLocation } from 'react-router-dom';

interface UseResultGroupsInterface {
  getResultGroups: (filter: DiscussionFilter, page: number) => Promise<void>;
  initialLoad: (mode: 'full' | 'light') => void;
  mergeFilter: (filter: DiscussionFilter) => DiscussionFilter;
}

export function useResultGroupsItems (): UseResultGroupsInterface {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const resultsDates = useSelector((state: RootState) => state.results.resultsDates);
  const resultsNetworks = useSelector((state: RootState) => state.results.resultsNetworks);
  const resultsAreas = useSelector((state: RootState) => state.results.resultsAreas);
  const resultsRegions = useSelector((state: RootState) => state.results.resultsRegions);
  const resultsCities = useSelector((state: RootState) => state.results.resultsCities);
  const resultsCustomers = useSelector((state: RootState) => state.results.resultsCustomers);
  const page = useSelector((state: RootState) => state.results.page);
  const perPage = useSelector((state: RootState) => state.results.perPage);

  const mergeFilter = useCallback((filter: DiscussionFilter) => {
    const fullFilter: DiscussionFilter = { ...filter };

    if (resultsDates && resultsDates.length) {
      fullFilter.dateFrom = moment(resultsDates[0]).format('YYYY-MM-DD');
      fullFilter.dateTo = moment(resultsDates[1]).format('YYYY-MM-DD');
    }

    if (resultsCustomers && Object.keys(resultsCustomers).length) {
      fullFilter.customers = Object.keys(resultsCustomers);
    }

    if (resultsNetworks && Object.keys(resultsNetworks).length) {
      fullFilter.tradeNetworks = Object.keys(resultsNetworks);
    }

    if (resultsRegions) {
      fullFilter.regions = resultsRegions;
    }

    if (resultsCities) {
      fullFilter.cities = resultsCities;
    }

    if (resultsAreas && resultsAreas.length) {
      fullFilter.regionAreas = resultsAreas.map((item) => item.id);
    }

    return fullFilter;
  }, [resultsAreas, resultsCities, resultsCustomers, resultsDates, resultsNetworks, resultsRegions]);

  const getResultGroups = useCallback(async (filter: DiscussionFilter, page) => {
    dispatch(setLoadingResultGroups({ loading: true }));
    const data: { error?: any } = await getReportResultGroups(page, filter, perPage);

    if (data.error) {
      dispatch(setLoadingResultGroups({ loading: false }));

      return;
    }

    const count = get(data, ['count'], 0);
    const items = get(data, ['items'], []) as Array<ReportResultGroup>;

    dispatch(setLoadingResultGroups({ loading: false }));
    dispatch(receiveResultGroups({ count, filter, items, page }));
  }, [dispatch, perPage]);

  const initialLoad = useCallback((mode: 'full' | 'light' = 'light') => {
    let inDiscussionPage = 1;

    if (location.pathname.includes('in-discussion') || location.pathname.includes('discussion-catalog')) {
      inDiscussionPage = page;
    }

    if (mode === 'light') {
      void getResultGroups(mergeFilter({ approvalStatuses: ['declined', 'no_claim', 'new'], statuses: ['active'] }), inDiscussionPage);
    } else {
      dispatch(reset());
      void getResultGroups({ approvalStatuses: ['declined', 'no_claim', 'new'], statuses: ['active'] }, inDiscussionPage);
    }
  }, [dispatch, getResultGroups, location, mergeFilter, page]);

  return {
    getResultGroups,
    initialLoad,
    mergeFilter
  };
}

export default useResultGroupsItems;
