// Copyright 2020 @po-polochkam authors & contributors
import type { ReportMistakeType, ProductCategoryType } from 'api/abstractions/result';

import React, { memo, ReactNode, useCallback, useState, useRef, useEffect } from 'react';
import Select, { LabeledValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import useResult from 'hooks/useResult';
import useResultMistakes from 'hooks/useResultMistakes';

import addItemIcon from 'assets/svg/addItem.svg';
import clearIcon from 'assets/svg/clearIcon.svg';

import ErrorSelectorContent, { ErrorType, ErrorItemType, ProductType } from './ErrorSelectorContent';

import './styles.less';

const { Option } = Select;

interface SelectCommonProps {
  allowClear?: boolean;
  category: ProductCategoryType;
  defaultValue?: string | string[] | number | number[] | LabeledValue | LabeledValue[];
  mistake?: ReportMistakeType
  mode?: 'multiple' | 'tags';
  reportResultId: string;
  resultId: string;
  suffixIcon?: ReactNode;
}

const defaultValue = 'select error';

const ErrorSelector: React.FC<SelectCommonProps> = (props) => {
  const { allowClear, category, mistake, mode, reportResultId, resultId } = props;
  const { t } = useTranslation();
  const [selectedErrorType, setSelectedErrorType] = useState<ErrorItemType>();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedError, setSelectedError] = useState<ErrorType>();
  const [ownErrorText, setOwnErrorText] = useState<string>('');
  const [errorCleared, setErrorCleared] = useState<boolean>();
  const [selectedProducts, setSelectedProducts] = useState<{ [key: string]: ProductType }>({});
  const [value, setValue] = useState('select error');
  const selectComponent = useRef<HTMLDivElement>(null);
  const selectContent = useRef<HTMLDivElement>(null);
  const { fetchResult } = useResult(resultId);
  const { deleteResultMistake } = useResultMistakes();
  const options = [{ label: (t('select error') || '').toString(), value: 'select error' }];

  const closeDropdown = useCallback(() => {
    setOpen(false);

    if (selectComponent && selectComponent.current) {
      selectComponent.current.blur();
    }
  }, []);

  const handleClickOutside = useCallback((event: Event) => {
    const el = selectComponent?.current;
    const innerEl = selectContent?.current;
    const node: Node = event.target as Node;

    if (!el || (el.contains && el.contains(node))) {
      return;
    }

    if (!innerEl || (innerEl.contains && innerEl.contains(node))) {
      return;
    }

    closeDropdown();
  }, [closeDropdown]);

  const clearSelector = useCallback(() => {
    setSelectedProducts({});
    setSelectedError(undefined);
    setSelectedErrorType(undefined);
    setValue(defaultValue);
    setErrorCleared(true);
    setOwnErrorText('');
  }, []);

  const onClear = useCallback(async () => {
    if (mistake) {
      await deleteResultMistake(resultId, mistake.id);
      await fetchResult();
    }

    clearSelector();
    closeDropdown();
  }, [clearSelector, closeDropdown, deleteResultMistake, fetchResult, resultId, mistake]);

  const onSelectError = useCallback((error: ErrorType | undefined) => {
    if (!error) {
      setSelectedError(undefined);

      return;
    }

    setValue(error.name);
    setSelectedError(error);

    if (!error.hasProducts) {
      closeDropdown();
    }
  }, [closeDropdown]);

  const onAcceptProducts = useCallback(async () => {
    closeDropdown();
    await fetchResult();
    clearSelector();
  }, [clearSelector, closeDropdown, fetchResult]);

  const presetMistake = useCallback(() => {
    if (mistake && value === 'select error' && !errorCleared) {
      if (!mistake.comment) {
        setValue(mistake.reportResultMistake.name);
      } else {
        setValue(mistake.comment);
      }

      if (!mistake.reportResultMistake.id) {
        // для своих ошибок
        setSelectedErrorType({ errors: [], id: 'own', name: 'Свой вариант' });
        setSelectedError({ hasProducts: !!mistake.productsIds.length, id: 'own', mistakeId: mistake.id, name: mistake.comment });
      } else {
        // setSelectedErrorType(type);
        setSelectedErrorType({ errors: [], id: mistake.reportResultMistake.type, name: '' });
        setSelectedError({ ...mistake.reportResultMistake, id: mistake.reportResultMistake.id, mistakeId: mistake.id });
      }

      if (mistake.productsIds.length) {
        setSelectedProducts({
          ...Object.fromEntries(
            mistake.productsIds.map((productId: string) => ([productId, { id: productId, name: '' }]))
          )
        });
      }
    }
  }, [errorCleared, mistake, value]);

  useEffect(() => {
    presetMistake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });

  return (
    <div className={`error-selector ${value !== 'select error' ? 'has-value' : ''}`}>
      <Select
        allowClear={allowClear}
        clearIcon={
          (allowClear)
            ? <img
              alt='clear'
              src={clearIcon}
            /> : <> </>
        }
        disabled={!allowClear}
        dropdownRender={(menu) => (
          <ErrorSelectorContent
            category={category}
            clearSelector={clearSelector}
            fetchResult={fetchResult}
            hasMistake={!!mistake?.id}
            menu={menu}
            onAcceptProducts={onAcceptProducts}
            ownErrorText={ownErrorText}
            ref={selectContent}
            reportResultId={reportResultId}
            resultId={resultId}
            selectedError={selectedError}
            selectedErrorType={selectedErrorType}
            selectedProducts={selectedProducts}
            setOwnErrorText={setOwnErrorText}
            setSelectedError={onSelectError}
            setSelectedErrorType={setSelectedErrorType}
            setSelectedProducts={setSelectedProducts}
          />
        )}
        getPopupContainer={(trigger: { parentNode: HTMLElement }) => trigger.parentNode}
        mode={mode}
        onClear={onClear}
        onFocus={setOpen.bind(null, true)}
        open={open}
        /* antd требует тип BaseSelectRef, находящийся в rc-select, экпортнуть сам тип из antd нет возможности
            @ts-ignore */
        ref={selectComponent}
        style={{ width: '100%' }}
        suffixIcon={
          (allowClear)
            ? <img
              alt='add item'
              onClick={setOpen.bind(null, true)}
              src={addItemIcon}
            /> : <> </>
        }
        value={value}
      >
        { options.map((option) => (
          <Option
            key={option.value}
            label={option.label}
            value={option.value}
          >
            <div className='option-label-item'>
              {option.label}
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default memo(ErrorSelector);
