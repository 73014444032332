// Copyright 2021 @po-polochkam authors & contributors
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import firebase from 'firebase';
import { useCallback, useEffect, useState } from 'react';
import useFirebaseToken from './useFirebaseToken';
import type { User } from 'api/abstractions/user';

declare global {
  interface Window {
    processEnv: {
      API_KEY: string;
      APP_ID: string;
      AUTH_DOMAIN: string;
      BACKEND_URL: string;
      CLIENT_SECRET: string;
      DATABASE_URL: string;
      MESSAGING_SENDER_ID: string;
      PROJECT_ID: string;
      STORAGE_BUCKET: string;
    }
  }
}

const firebaseConfig = {
  apiKey: window.processEnv.API_KEY,
  appId: window.processEnv.APP_ID,
  authDomain: window.processEnv.AUTH_DOMAIN,
  backendUrl: window.processEnv.BACKEND_URL,
  client_secret: window.processEnv.CLIENT_SECRET,
  databaseURL: window.processEnv.DATABASE_URL,
  messagingSenderId: window.processEnv.MESSAGING_SENDER_ID,
  projectId: window.processEnv.PROJECT_ID,
  storageBucket: window.processEnv.STORAGE_BUCKET
};

export interface ChatFirebaseInterface {
  firebaseDb: firebase.database.Database | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const firebaseApp = firebase.initializeApp({
  apiKey: firebaseConfig.apiKey,
  appId: firebaseConfig.appId,
  authDomain: firebaseConfig.authDomain,
  databaseURL: firebaseConfig.databaseURL,
  messagingSenderId: firebaseConfig.messagingSenderId,
  projectId: firebaseConfig.projectId,
  storageBucket: firebaseConfig.storageBucket
});

export default function useChatFirebase (user: User): ChatFirebaseInterface {
  const [firebaseDb, setFirebaseDb] = useState<firebase.database.Database>();
  const { firebaseToken } = useFirebaseToken(user);

  const signInDatabase = useCallback(() => {
    if (firebaseToken) {
      const auth = firebase.auth(firebaseApp);

      void auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      auth.signInWithCustomToken(firebaseToken)
        .then((/* userCredential: { user: any; } */) => {
          // const user = userCredential.user;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const db = firebase.database();

          setFirebaseDb(db);
        })
        .catch((error: { code: string, message: string }) => {
          console.log('signInWithCustomToken error', error.code, error.message);
        });
    }
  }, [firebaseToken]);

  useEffect(() => {
    signInDatabase();
  }, [signInDatabase]);

  return {
    firebaseDb
  };
}
