// Copyright 2020 @po-polochkam authors & contributors
import type { CityType, RegionAreaType, ItemType } from 'api/abstractions/dictionary';

import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setResultsAreas, setResultsCities } from 'store/resutlsReducer';
import SearchDropdown from 'components/SearchDropdown';
import useAreas from 'hooks/useAreas';
import useCities from 'hooks/useCities';
import { RootState } from 'store/store';

import './styles.less';

const AddressFilterBlock: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedBlock, setSelectedBlock] = useState<1 | 2>();
  const [citiesTerm, setCitiesTerm] = useState<string>();
  const [regionAreasTerm, setRegionAreasTerm] = useState<string>();
  const [selectedArea, setSelectedArea] = useState<RegionAreaType>();
  const [selectedCities, setSelectedCities] = useState<{[key: string]: CityType}>({});
  const { fetchRegionAreas, regionAreas } = useAreas();
  const { cities, fetchCities } = useCities();

  const citiesLoading = useSelector((state: RootState) => state.dictionary.citiesLoading);
  const regionAreasLoading = useSelector((state: RootState) => state.dictionary.regionAreasLoading);
  const resultsAreas = useSelector((state: RootState) => state.results.resultsAreas);
  const resultsCities = useSelector((state: RootState) => state.results.resultsCities);

  const elem = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: Event) => {
    const el = elem?.current;

    if (!el || el.contains((event?.target as Node) || null)) {
      return;
    }

    setSelectedBlock(undefined);
  }, []);

  const onSelectItem = useCallback((item: ItemType) => {
    const { id }: { id: string } = item;

    if (selectedBlock === 1) {
      const selectedAreaItem = regionAreas.find((regionArea) => regionArea.id === id);

      dispatch(setResultsAreas(selectedAreaItem ? [selectedAreaItem] : []));
      dispatch(setResultsCities([]));
    } else {
      const newSelectedCities: {[key: string]: CityType} = { ...selectedCities };

      if (newSelectedCities[id]) {
        delete newSelectedCities[id];
      } else {
        newSelectedCities[id] = item;
      }

      dispatch(setResultsCities(Object.keys(newSelectedCities)));
    }
  }, [dispatch, selectedBlock, selectedCities, regionAreas]);

  const clearFilter = useCallback(() => {
    if (selectedBlock === 1) {
      dispatch(setResultsAreas([]));
      dispatch(setResultsCities([]));
    } else {
      dispatch(setResultsCities([]));
    }

    setRegionAreasTerm(undefined);
    setCitiesTerm(undefined);
  }, [dispatch, selectedBlock]);

  const items = useMemo((): ItemType[] => {
    if (selectedBlock === 1 && regionAreas) {
      return regionAreas.map((regionArea: RegionAreaType) => ({ id: regionArea.id, name: regionArea.name }));
    } else if (selectedBlock === 2 && selectedArea) {
      return cities;
    }

    return [];
  }, [cities, selectedBlock, selectedArea, regionAreas]);

  const loading = useMemo(() => {
    if (selectedBlock === 1) {
      return regionAreasLoading;
    } else if (selectedBlock === 2) {
      return citiesLoading;
    }
  }, [citiesLoading, regionAreasLoading, selectedBlock]);

  const selectedItem = useMemo((): {[key: string]: ItemType} => {
    if (selectedBlock === 1 && selectedArea) {
      return { [selectedArea.id]: { id: selectedArea.id, name: selectedArea.name } };
    } else if (selectedBlock === 2 && selectedCities) {
      return selectedCities;
    }

    return {};
  }, [selectedBlock, selectedArea, selectedCities]);

  const defineSelectedArea = useCallback(() => {
    if (resultsAreas && resultsAreas.length) {
      const targetArea = resultsAreas[0];

      setSelectedArea(targetArea);
    } else {
      setSelectedArea(undefined);
    }
  }, [resultsAreas]);

  const defineSelectedCity = useCallback(() => {
    const targetCities = cities
      .filter((city: CityType) => resultsCities.includes(city.id));
    const newSelectedCities: { [key: string]: CityType } = {};

    targetCities.forEach((targetCity: CityType) => {
      newSelectedCities[targetCity.id] = targetCity;
    });

    setSelectedCities(newSelectedCities);
  }, [cities, resultsCities]);

  useEffect(() => {
    fetchRegionAreas({ term: regionAreasTerm });
  }, [fetchRegionAreas, regionAreasTerm]);

  useEffect(() => {
    if (selectedArea) {
      fetchCities({ regionAreas: [selectedArea.id], term: citiesTerm });
    }
  }, [citiesTerm, fetchCities, selectedArea]);

  useEffect(() => {
    defineSelectedArea();
  }, [defineSelectedArea]);

  useEffect(() => {
    defineSelectedCity();
  }, [defineSelectedCity]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });

  return (
    <div
      className='address-filter-block'
      ref={elem}
    >
      <div
        className={`block city ${selectedBlock === 1 ? 'selected' : ''}`}
        onClick={setSelectedBlock.bind(null, 1)}
      >
        <span className={`subtext ${selectedArea ? 'small' : 'empty'}`}>{t('region')}</span>
        <span className='subtext'>{selectedArea?.name}</span>
      </div>
      <div className='address-filter-block--divider' />
      <div
        className={`block district ${selectedBlock === 2 ? 'selected' : ''}`}
        onClick={setSelectedBlock.bind(null, 2)}
      >
        <span className={`subtext ${Object.values(selectedCities).length > 0 ? 'small' : 'empty'}`}>{t('city or district')}</span>
        { Object.values(selectedCities).length === 1 && (
          <span className='subtext'>{Object.values(selectedCities)[0].name}</span>
        )}
        { Object.values(selectedCities).length > 1 && (
          <span className='subtext'>{t('selected')} {Object.values(selectedCities).length}</span>
        )}
      </div>
      { selectedBlock === 1 && (
        <SearchDropdown
          clearFilter={clearFilter}
          items={items}
          loading={loading}
          onSelectItem={onSelectItem}
          searchString={regionAreasTerm}
          selected={selectedItem}
          setSearchString={setRegionAreasTerm}
        />
      )}

      { selectedBlock === 2 && (
        <SearchDropdown
          clearFilter={clearFilter}
          items={items}
          loading={loading}
          multiple
          onSelectItem={onSelectItem}
          searchString={citiesTerm}
          selected={selectedItem}
          setSearchString={setCitiesTerm}
        />
      )}
    </div>
  );
};

export default AddressFilterBlock;
