// Copyright 2020 @po-polochkam authors & contributors

import { useState, useEffect, RefObject } from 'react';

const useIntersection = (element: RefObject<HTMLDivElement>, rootMargin: string): boolean => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (element && element.current) {
      const htmlDivElement = element.current;
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState(entry.isIntersecting);
        }, { rootMargin }
      );

      element && observer.observe(htmlDivElement);

      return () => observer.unobserve(htmlDivElement);
    }

    return () => null;
  }, [element, rootMargin]);

  return isVisible;
};

export default useIntersection;
