// Copyright 2020 @po-polochkam authors & contributors

import type { ProductCategoryType, ReportMistakeType } from 'api/abstractions/result';
import { User } from 'api/abstractions/user';
import { useTranslation } from 'react-i18next';

import React, { memo, useMemo } from 'react';
import firebase from 'firebase';

import ErrorItem from 'components/ErrorItem';
import useChatFirebaseUnread from '../ChatComponent/hooks/useChatFirebaseUnread';

import './styles.less';

export interface DiscussListItemInterface {
  admin?: boolean;
  category: ProductCategoryType;
  firebaseDb?: firebase.database.Database;
  gray?: boolean;
  id: string;
  isOpen?: boolean;
  mistakes: ReportMistakeType[];
  newState?: boolean;
  onItemClick?: (resultId: string) => void;
  user: User;
}

const DiscussListItem: React.FC<DiscussListItemInterface> = (props) => {
  const { admin, category, firebaseDb, gray, id, isOpen, mistakes, onItemClick, user } = props;
  const { unreadMessages } = useChatFirebaseUnread(id, firebaseDb, user?.userId);
  const { t } = useTranslation();

  const className = useMemo(() => {
    let baseClass = 'discuss-list-item';

    if (gray && !unreadMessages) {
      baseClass += ' gray';
    }

    return baseClass;
  }, [gray, unreadMessages]);

  return (
    <div
      className={className}
      onClick={onItemClick && onItemClick.bind(null, id)}
    >
      {/* <div className='badge'>
        {t('layout')}
      </div> */}
      <div className='block category'>
        {category.name}
      </div>
      <div
        className='block errors'
      >
        { mistakes && mistakes.map((mistake) => (
          <ErrorItem
            key={mistake.id}
            text={mistake.comment || mistake.reportResultMistake.name}
          />
        ))}
        { mistakes.length > 0 && (
          <div className='errors--gradient' />
        )}
      </div>
      <div className='discussion-status'>
        { (!admin && isOpen !== undefined) && (
          <>
            { !isOpen && (
              <span>
                {t('closed')}
              </span>
            )}
            { isOpen && (
              <span className='green'>
                {t('opened')}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(DiscussListItem);
