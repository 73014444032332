// Copyright 2021 @po-polochkam authors & contributors
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import type { User } from 'api/abstractions/user';
import request from 'api/request';

export interface ChatFirebaseInterface {
  accessToken: string | undefined;
  firebaseToken: string | undefined;
  loading: boolean;
}

export type JWTType = {
  aud: string;
  exp: number;
  iat: number;
  jti: string;
  nbf: number;
  scopes: Array<string>; // ROLE_CUSTOMER
  sub: string;
}

export function decodeToken (token: string): JWTType | null {
  try {
    // if the token has more or less than 3 parts or is not a string
    // then is not a valid token
    if (token.split('.').length !== 3) {
      return null;
    } else {
      // decode and parse to json
      return jwtDecode(token);
    }
  } catch (error) {
    // Return null if something goes wrong
    return null;
  }
}

export default function useFirebaseToken (user: User): ChatFirebaseInterface {
  const [accessToken, setAccessToken] = useState<string>();
  const [firebaseToken, setFirebaseToken] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getFirebaseToken = useCallback(async () => {
    if (accessToken && !loading && !firebaseToken && !error) {
      setLoading(true);

      const resp = await request({
        method: 'POST',
        url: '/merch/v1/firebase/token'
      });

      if (!resp) {
        return;
      }

      const data: { data: { 'access_token': string }, status: number } = resp;

      if (data.status !== 200) {
        setError(true);
        setLoading(false);
        console.error('firebase token fetch failed');
      }

      // eslint-disable-next-line camelcase
      const content: { access_token: string } = data.data;

      setFirebaseToken(content.access_token);

      setLoading(false);
    }
  }, [accessToken, error, firebaseToken, loading]);

  useEffect(() => {
    if (user) {
      setAccessToken(user.token);
    }
  }, [user]);

  useEffect(() => {
    void getFirebaseToken();
  }, [getFirebaseToken]);

  return {
    accessToken,
    firebaseToken,
    loading
  };
}
