// Copyright 2020 @po-polochkam authors & contributors
import React from 'react';

const MenuRequestsIcon: React.FC = () => {
  return (
    <span className='menu-icon'>
      <svg
        fill='none'
        height='27'
        viewBox='0 0 25 27'
        width='25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M1.63369 18.7892C0.38881 17.7959 0.388812 15.9036 1.63369 14.9102L10.7848 7.60788C11.6742 6.8982 12.9323 6.88433 13.8371 7.57424L23.4138 14.8766C24.7162 15.8696 24.7162 17.8298 23.4138 18.8229L13.8371 26.1252C12.9323 26.8151 11.6742 26.8013 10.7848 26.0916L1.63369 18.7892Z' fill="#19C262"/>
        <g filter='url(#filter0_b)'>
          <path d='M1.63369 12.0898C0.38881 11.0964 0.388812 9.20413 1.63369 8.21075L10.7848 0.908421C11.6742 0.198738 12.9323 0.184871 13.8371 0.874776L23.4138 8.1771C24.7162 9.17018 24.7162 11.1304 23.4138 12.1234L13.8371 19.4258C12.9323 20.1157 11.6742 20.1018 10.7848 19.3921L1.63369 12.0898Z' fill="#E3E3E3" fillOpacity="0.7"/>
        </g>
        <defs>
          <filter id='filter0_b' x="-4.08524" y="-4.41886" width="33.2615" height="29.1382" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImage" stdDeviation="2.39272"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
          </filter>
        </defs>
      </svg>
    </span>
  );
};

export default MenuRequestsIcon;
