// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, ReactNode } from 'react';
import Modal from 'antd/lib/modal';
import closeModalIcon from 'assets/svg/close-modal-icon.svg';

import './styles.less';

interface ModalCommonProps {
  footer?: Array<ReactNode> | ReactNode | null;
  modalContent?: ReactNode | string;
  setVisible: (status: boolean) => void;
  visible: boolean;
  title?: string;
  width?: number;
}

const ModalCommon: React.FC<ModalCommonProps> = (props) => {
  const { footer = null, modalContent, setVisible, title, visible, width } = props;

  return (
    <Modal
      centered
      closeIcon={
        <img
          alt='closeModalIcon'
          src={closeModalIcon}
        />
      }
      footer={footer}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      title={title}
      visible={visible}
      width={width}
    >
      {modalContent}
    </Modal>
  );
};

export default memo(ModalCommon);
