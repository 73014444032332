// Copyright 2020 @po-polochkam authors & contributors

import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  { firstName: 'Test', id: '1', lastName: 'TestLast' }
];

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {}
});

export default userSlice.reducer;
