// Copyright 2020 @po-polochkam authors & contributors

import './styles.less';

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultProduct, SmallProduct } from 'api/abstractions/dictionary';
import { getProductsByResult } from 'api/dictionary';

import useIsMountedRef from 'hooks/useIsMountedRef';
import closeModalIcon from 'assets/svg/close-modal-icon.svg';

interface ResultsImageGalleryProps {
  categoryId: string;
  resultId: string;
  reportId: string;
}

const LackOfProducts: React.FC<ResultsImageGalleryProps> = (props) => {
  const { t } = useTranslation();
  const mountedRef = useIsMountedRef();
  const { categoryId, reportId, resultId } = props;
  const [products, setProducts] = useState<SmallProduct[]>([]);
  const [productToOpen, setProductToOpen] = useState<SmallProduct>();

  const onProductClick = useCallback((product: SmallProduct) => {
    if (product.dctPhotoUrl !== null) {
      setProductToOpen(product);
    }
  }, []);

  const fetchProductsByResult = useCallback(async (): Promise<void> => {
    const data: ResultProduct = await getProductsByResult(resultId, { category: categoryId, page: 0, perPage: 300, report: reportId }) as ResultProduct;

    mountedRef.current && setProducts(data.skus);
  }, [categoryId, mountedRef, resultId, reportId]);

  const hasProductsPresence = useMemo(() => {
    return !!products.find((product: SmallProduct) => product.presence === 'no');
  }, [products]);

  useEffect(() => {
    void fetchProductsByResult();
  }, [fetchProductsByResult]);

  /*
  product.presence:
  yes - есть в наличии
  no - нет в наличии
  undefined - Не удалось распознать
  null - еще не распознан
  если нет ни одного продукта с распознаванием, ничего не показываем
   */
  if (!hasProductsPresence) {
    return <div />;
  }

  return (
    <div className='error-selected'>
      <div className='error-selected--header'>{t('not in stock')}</div>
      {products.map((product: SmallProduct) => {
        const tsdNotAvailable = (product.dctPhotoUrl === null) ? t('TSD not available') : '';

        if (product.imagePath && product.presence === 'no') {
          return (
            <div
              className='error-selected--product'
              key={product.name}
              onClick={onProductClick.bind(null, product)}
              style={{ cursor: (product.dctPhotoUrl === null) ? 'default' : 'pointer' }}
            >
              <img
                alt='product-image'
                src={product.imagePath}
              />
              <span>{product.name + ' ' + tsdNotAvailable}</span>
            </div>
          );
        }
      })}
      { productToOpen && productToOpen.dctPhotoUrl && (
        <div className='product-tsd'>
          <div
            className='product-tsd--close'
            onClick={setProductToOpen.bind(null, undefined)}
          >
            <img
              alt='closeModalIcon'
              src={closeModalIcon}
            />
          </div>
          <div className='product-tsd--content'>
            <img
              alt=''
              src={productToOpen.dctPhotoUrl}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(LackOfProducts);
