// Copyright 2020 @po-polochkam authors & contributors
import type { DiscussionFilter } from 'api/abstractions/result';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import useResultGroupsItems from 'hooks/useResultGroupsItems';
import { ReportResultGroup } from 'api/abstractions/result';

interface UseResultGroupsInterface {
  getResultGroups: (filter: DiscussionFilter, page: number) => Promise<void>;
  groupsCount: number;
  initialLoad: (mode: 'full' | 'light') => void;
  resultGroups: Array<ReportResultGroup>;
}

export const resultsPerPage = 10;

export function useReportResultGroups (): UseResultGroupsInterface {
  const groupsCount = useSelector((state: RootState) => state.results.groupsCount);
  const resultGroups = useSelector((state: RootState) => state.results.resultGroups);
  const { getResultGroups, initialLoad } = useResultGroupsItems();

  return {
    getResultGroups,
    groupsCount,
    initialLoad,
    resultGroups
  };
}

export default useReportResultGroups;
