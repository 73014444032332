// Copyright 2020 @po-polochkam authors & contributors
import { RequestErrorType } from 'api/request';
import { LoginResponse } from 'api/abstractions/user';

import React, { memo, ReactElement, useCallback } from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import { useMutation, useQueryCache } from 'react-query';

import { object as YupObject, string as YupString } from 'yup';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers, FormikErrors } from 'formik';
import ButtonCommon from 'uiKit/Button';
import { loginUser } from 'api/user';
import { isAdmin, isCustomer, setUserByToken } from 'api/manageToken';

interface Values {
  password: string;
  username: string;
}

const LoginForm = (): ReactElement => {
  const { t } = useTranslation();
  const cache = useQueryCache();
  const history = useHistory();
  const [login]: [(values: Values) => Promise<void>, { data: RequestErrorType }] = useMutation(loginUser, {
    onSuccess: () => {
      // Query Invalidations
      void cache.invalidateQueries('login');
    }
  });

  const initialValues: Values = {
    password: '',
    username: ''
  };

  const redirectToHomePage = useCallback(() => {
    const admin = isAdmin();
    const customer = isCustomer();

    if (admin) {
      history.push('/home/discussion-catalog');
    } else if (customer) {
      history.push('/home/results-verification/on-review');
    }
  }, [history]);

  const onSubmit = useCallback(async (values: Values, { setErrors }: FormikHelpers<Values>) => {
    const result = await login({ ...values });
    const requestStatus = get(result, ['status'], null);
    const errors: FormikErrors<Values> = {};

    if (requestStatus === 401) {
      errors.password = (t('The user credentials were incorrect') || '').toString();
      errors.username = (t('The user credentials were incorrect') || '').toString();
      setErrors(errors);
    } else if (requestStatus === 429) {
      errors.password = (t('Too Many Requests') || '').toString();
      errors.username = (t('Too Many Requests') || '').toString();
      setErrors(errors);
    } else if (requestStatus === 200) {
      const requestData: LoginResponse = get(result, ['data'], null);

      setUserByToken(requestData, values.username, values.username);

      redirectToHomePage();
    }
  }, [login, t, redirectToHomePage]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={() => (
        <Form name='LoginForm'>
          <Form.Item
            name='username'
          >
            <Input
              name='username'
              placeholder={(t('login') || '').toString()}
              type='text'
            />
          </Form.Item>
          <Form.Item
            name='password'
          >
            <Input
              name='password'
              placeholder={(t('password') || '').toString()}
              type='password'
            />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                name='submit'
              >
                <ButtonCommon
                  block
                  htmlType='submit'
                  type='primary'>
                  {(t('enter') || '').toString()}
                </ButtonCommon>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      validationSchema={
        YupObject().shape({
          password: YupString()
            .required((t('password is a required field') || '').toString()),
          username: YupString()
            .required((t('username is a required field') || '').toString())
        })
      }
    />
  );
};

export default memo(LoginForm);
