// Copyright 2020 @po-polochkam authors & contributors
import qs from 'qs';
import request from './request';

export const getDailyResults = async (page: number, filters: any, perPage: number = 10) => {
  const data = await request({
    url: '/customer/v1/daily-results',
    method: 'GET',
    params: {
      page,
      filters,
      'per-page': perPage
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params,  { arrayFormat: 'brackets' })
    }
  });

  return data.data;
};

// discussing - active
export const getReportResultGroups = async (page: number, filters: any, perPage: number = 10) => {
  const data = await request({
    url: `/customer/v1/report-result-groups`,
    method: 'GET',
    params: {
      page,
      'per-page': perPage,
      filters
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params,  { arrayFormat: 'brackets' })
    }
  });

  return data.data;
}

/**
 * Делает запрос за картинкой для образцов отчета
 * @param id - resultId
 * @param reportId - id самого отчета
 * @return Возвращает созданную ссылку на картинку
 * */
export const fetchReportSampleImage = async (id: string | undefined, reportId: string): Promise<string | null> => {
  if (!id || !reportId) {
    return null;
  }

 try {
   const response = await request({
     method: 'GET',
     url: `/customer/v1/daily-results/${id}/report-planograms/${reportId}.jpeg`,
     responseType: 'blob',
     hideNotificationOnError: {
       400: true
     }
   })
   // todo: сделать адекватный механизм отлавливания ошибок во время рефакторинга
   if (response.status >= 400) {
     return null;
   }
   /**
    * Апи отсылает сразу картинку в base64
    * Напрямую сделать вставить ссылку в img.src нет возможности из-за проверки апи авторизации в authorization заголовке
    * Таким образом, создаем url на картинку на самом хосте динамически, декодируя картинку из base64
    * */
   const urlCreator = window.URL || window.webkitURL;
   const imageUrl = urlCreator.createObjectURL(response.data);

   return imageUrl
 }
  catch (e) {
    console.log('caught error: ', e);
    return null;
  }
}

export const getResult = async (id: string) => {
  const data = await request({
    url: `/customer/v1/daily-results/${id}`,
    method: 'GET'
  });

  return data.data;
};

export const getResultGroup = async (id: string) => {
  const data = await request({
    url: `/customer/v1/report-result-groups/${id}`,
    method: 'GET'
  });

  return data.data;
};

export const startReviewing = async (filters: any, ids: string[]) => {
  const data = await request({
    url: '/customer/v1/daily-results/start-reviewing',
    method: 'POST',
    data: {
      filters: {
        ...filters,
        ids
      }
    }
  });

  return data.data;
};

export const applyWithoutVerification = async (filters: any, ids: string[]) => {
  const data = await request({
    url: '/customer/v1/daily-results/approve',
    method: 'POST',
    data: {
      filters: {
        ...filters,
        ids
      }
    }
  });

  return data.data;
}

export const finishReviewing = async (resultId: string) => {
  const data = await request({
    url: `/customer/v1/daily-results/${resultId}/done-reviewing`,
    method: 'POST'
  });

  return data.data;
}

export const doneReviewing = async (filters: any, ids: string[]) => {
  const data = await request({
    url: '/customer/v1/daily-results/review',
    method: 'POST',
    data: {
      filters: {
        ...filters,
        ids
      }
    }
  });

  return data.data;
}

export const acceptCustomerResponse = async (customerDailyResultId: string, resultGroupId: string) => {
  const data = await request({
    url: `/customer/v1/daily-results/${customerDailyResultId}/report-result-groups/${resultGroupId}/accept`,
    method: 'POST'
  });

  return data.data;
};

export const declineCustomerResponse = async (customerDailyResultId: string, resultGroupId: string, customerClaimResponse: string) => {
  const data = await request({
    url: `/customer/v1/daily-results/${customerDailyResultId}/report-result-groups/${resultGroupId}/decline`,
    method: 'POST',
    data: {
      customerClaimResponse
    }
  });

  return data.data;
};

export const acceptClaim = async (customerDailyResultId: string, resultGroupId: string) => {
  const data = await request({
    url: `/customer/v1/daily-results/${customerDailyResultId}/report-result-groups/${resultGroupId}/accept-claim-declining`,
    method: 'POST'
  });

  return data.data;
};

export const declineClaim = async (customerDailyResultId: string, resultGroupId: string) => {
  const data = await request({
    url: `/customer/v1/daily-results/${customerDailyResultId}/report-result-groups/${resultGroupId}/decline-claim-declining`,
    method: 'POST'
  });

  return data.data;
};