// Copyright 2020 @po-polochkam authors & contributors
import type { FetchCitiesInterface } from 'api/abstractions/dictionary';
import request from './request';
import { API_CUSTOMER } from 'api/config';

export const getRegions = async () => {
  const data = await request({
    url: '/customer/v1/dictionary/regions',
    method: 'GET'
  });

  return data.data;
};

export const getRegionAreas = async ({ regionId, term } : { regionId?: string, term?: string }) => {
  const data = await request({
    url: `${API_CUSTOMER}dictionary/region-areas`,
    method: 'GET',
    params: {
      region: regionId,
      term,
    }
  });

  return data.data;
};

export const getCities = async ({ regionAreas, regionId, term }: FetchCitiesInterface) => {
  const data = await request({
    url: `${API_CUSTOMER}dictionary/cities`,
    method: 'GET',
    params: {
      region: regionId,
      regionAreas: regionAreas,
      term,
    }
  });

  return data.data;
};

export const getTradeNetworks = async () => {
  const data = await request({
    url: `${API_CUSTOMER}dictionary/trade-networks`,
    method: 'GET',
    params: {
      // 'page': 1,
      'per-page': 50
    }
  });

  return data.data;
};

export const getCustomers = async () => {
  const data = await request({
    url: `${API_CUSTOMER}dictionary/customers`,
    method: 'GET',
    params: {
      'page': 1,
      'per-page': 100
    }
  });

  return data.data;
};

export const getCustomerClaimResponses = async () => {
  const data = await request({
    url: `${API_CUSTOMER}dictionary/customer-claim-responses`,
    method: 'GET',
    params: {
      'page': 1,
      'per-page': 50
    }
  });

  return data.data;
}

export const getReportMistakesTypes = async () => {
  const data = await request({
    url: `${API_CUSTOMER}report-result-mistakes-types`,
    method: 'GET'
  });

  return data.data;
}

export const getReportMistakesByType = async (typeId: string) => {
  const data = await request({
    url: `${API_CUSTOMER}report-result-mistakes-types/${typeId}/mistakes`,
    method: 'GET'
  });

  return data.data;
}

export const addMistakeToReport = async (resultId: string, reportResultId:string, mistakeId: string, productsIds: string[]) => {
  const data = await request({
    url: `${API_CUSTOMER}daily-results/${resultId}/mistakes`,
    method: 'POST',
    data: {
      customerReportResult: reportResultId,
      reportResultMistake: mistakeId,
      productsIds: productsIds
    }
  });

  return data.data;
};

export const addCustomMistakeToReport = async (resultId: string, reportResultId:string, ownErrorText: string, productsIds: string[]) => {
  const data = await request({
    url: `${API_CUSTOMER}daily-results/${resultId}/mistakes`,
    method: 'POST',
    data: {
      comment: ownErrorText,
      customerReportResult: reportResultId,
      productsIds: productsIds
    }
  });

  return data.data;
};

export const deleteMistake = async (resultId: string, mistakeId: string) => {
  // /customer-daily-results/{id}/mistakes/{mistake_id}
  const data = await request({
    url: `${API_CUSTOMER}daily-results/${resultId}/mistakes/${mistakeId}`,
    method: 'DELETE'
  });

  return data.data;
}

export const getProductsByResult = async (resultId: string, { category, report, group, term, page, perPage }: { category?: string; report?: string; group?: string, term?: string, page?: number, perPage?: number }) => {
  const data = await request({
    url: `${API_CUSTOMER}daily-results/${resultId}/skus`,
    method: 'GET',
    params: {
      category,
      report,
      group,
      term,
      page,
      'per-page': perPage,
      'sort[name]': 'ASC'
    }
  });

  return data.data;
};