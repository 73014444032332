// Copyright 2020 @po-polochkam authors & contributors
import type { RegionAreaType, RegionType } from 'api/abstractions/dictionary';

import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { setLoadingRegionAreas } from 'store/dictionaryReducer';
import { getRegionAreas } from 'api/dictionary';

interface Params {
  region?: RegionType | null;
  term?: string;
}

export interface UseAreasInterface {
  fetchRegionAreas: (params: Params) => void;
  regionAreas: RegionAreaType[];
}

export default function useAreas (): UseAreasInterface {
  const dispatch = useAppDispatch();
  const [regionAreas, setRegionAreas] = useState<RegionAreaType[]>([]);

  const fetchRegionAreas = useCallback(async ({ region, term }: { region?: RegionType | null, term?: string }) => {
    dispatch(setLoadingRegionAreas(true));
    const data = await getRegionAreas({ regionId: region?.id, term }) as RegionAreaType[];

    if ('status' in data) {
      return;
    }

    dispatch(setLoadingRegionAreas(false));
    setRegionAreas(data);
  }, [dispatch]);

  return {
    fetchRegionAreas,
    regionAreas
  };
}
