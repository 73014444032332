// Copyright 2020 @po-polochkam authors & contributors
import type { ResultMistakeType, ResultMistake, ResultProduct, SmallProduct } from 'api/abstractions/dictionary';

import { useCallback, useState } from 'react';
import { addCustomMistakeToReport, addMistakeToReport, deleteMistake, getReportMistakesTypes, getReportMistakesByType, getProductsByResult } from 'api/dictionary';

export interface UseCitiesInterface {
  addCustomMistake: (resultId: string, reportResultId:string, ownErrorText: string, productsIds: string[]) => Promise<void>;
  addMistake: (resultId: string, reportResultId: string, mistakeId: string, productsIds: string[]) => Promise<void>;
  deleteResultMistake: (resultId: string, mistakeId: string) => Promise<void>;
  fetchResultMistakeByType: (typeId: string) => void;
  fetchResultMistakeTypes: () => void;
  fetchResultProducts: (resultId: string, categoryId?: string) => void;
  mistakesByType: ResultMistake[];
  resultMistakeTypes: ResultMistakeType[];
  resultProducts: SmallProduct[];
}

export default function useResultMistakes (): UseCitiesInterface {
  const [resultMistakeTypes, setResultMistakeTypes] = useState<ResultMistakeType[]>([]);
  const [mistakesByType, setMistakesByType] = useState<ResultMistake[]>([]);
  const [resultProducts, setResultProducts] = useState<SmallProduct[]>([]);

  const addMistake = useCallback(async (resultId: string, reportResultId:string, mistakeId: string, productsIds: string[]) => {
    await addMistakeToReport(resultId, reportResultId, mistakeId, productsIds);
  }, []);

  const addCustomMistake = useCallback(async (resultId: string, reportResultId:string, ownErrorText: string, productsIds: string[]) => {
    await addCustomMistakeToReport(resultId, reportResultId, ownErrorText, productsIds);
  }, []);

  const deleteResultMistake = useCallback(async (resultId: string, mistakeId: string) => {
    await deleteMistake(resultId, mistakeId);
  }, []);

  const fetchResultMistakeTypes = useCallback(async () => {
    const data = await getReportMistakesTypes() as ResultMistakeType[];

    setResultMistakeTypes(data);
  }, []);

  const fetchResultMistakeByType = useCallback(async (typeId: string) => {
    const data = await getReportMistakesByType(typeId) as ResultMistake[];

    setMistakesByType(data);
  }, []);

  const fetchResultProducts = useCallback(async (resultId: string, categoryId?: string) => {
    const data: ResultProduct = await getProductsByResult(resultId, { category: categoryId, page: 0, perPage: 300 }) as ResultProduct;

    setResultProducts(data.skus);
  }, []);

  return {
    addCustomMistake,
    addMistake,
    deleteResultMistake,
    fetchResultMistakeByType,
    fetchResultMistakeTypes,
    fetchResultProducts,
    mistakesByType,
    resultMistakeTypes,
    resultProducts
  };
}
