// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AddressFilterBlock from 'components/AddressFilterBlock';
import useResultGroupsItems from 'hooks/useResultGroupsItems';
import DiscussionAdminList from 'components/DiscussionAdminList';

import './styles.less';

const DiscussionCatalog: React.FC = () => {
  const { t } = useTranslation();
  const { initialLoad } = useResultGroupsItems();

  useEffect(() => {
    void initialLoad('light');
  }, [initialLoad]);

  return (
    <div data-testid='discussion-catalog'>
      <h1>{t('discussing errors with the customer')}</h1>
      <AddressFilterBlock />
      <DiscussionAdminList />,
    </div>
  );
};

export default memo(DiscussionCatalog);
