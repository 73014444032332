// Copyright 2020 @po-polochkam authors & contributors

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import App from 'containers/App';
import store from 'store/store';
import './i18n';
// import OnBoarding from 'containers/OnBoarding';
import reportWebVitals from './reportWebVitals';
import './index.css';
// warning! Less-loader-7 not allows inline js in and design, wait for new version of ant design for less loader 7
import './index.less';

interface QueryCacheInterface extends QueryCache {
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const queryCache = new QueryCache() as QueryCacheInterface;

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <App />
      </ReactQueryCacheProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
