// Copyright 2020 @po-polochkam authors & contributors

import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import userReducer from './userReducer';
import resutlsReducer from './resutlsReducer';
import dictionaryReducer from './dictionaryReducer';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    dictionary: dictionaryReducer,
    results: resutlsReducer,
    user: userReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): Dispatch => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export default store;
