// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TabsCommon from 'uiKit/TabsCommon';

import { setPage } from '../../store/resutlsReducer';
import { useAppDispatch } from '../../store/store';
import useResultsItems from 'hooks/useResultsItems';

import AddressFilterBlock from 'components/AddressFilterBlock';
import ResultsTabsList from 'components/ResultsTabsList';

const ResultsVerification: React.FC = () => {
  const { t } = useTranslation();
  const { initialLoad } = useResultsItems();
  const dispatch = useAppDispatch();

  const onChangeTab = useCallback(({ history, tabsPath }: { history: { push: (param: string) => void }, tabsPath: string}, key: string) => {
    dispatch(setPage(1));
    history.push(`${tabsPath}${key}`);
  }, [dispatch]);

  useEffect(() => {
    initialLoad('light');
  }, [initialLoad]);

  return (
    <div data-testid='result-verification'>
      <h1>{t('results verification')}</h1>
      <AddressFilterBlock />
      <TabsCommon
        destroyInactiveTabPane
        onChangeTab={onChangeTab}
        tabs={ResultsTabsList()}
        tabsPath={'/home/results-verification/'}
      />
    </div>
  );
};

export default memo(ResultsVerification);
