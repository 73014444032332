// Copyright 2020 @po-polochkam authors & contributors
import { ResultGroupMap } from 'api/abstractions/result';

import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import NetworkFilter from 'components/NetworkFilter';
import CustomerFilter from 'components/CustomerFilter';
import DateFilter from 'components/DateFilter';
import clearFilters from 'assets/svg/clearFilters.svg';
import useResultGroupsItems from 'hooks/useResultGroupsItems';

import './styles.less';

interface ListCheckingHeaderProps {
  count: number;
  resultsMappedByDate: ResultGroupMap;
}

const AdminDiscussionListFilters: React.FC<ListCheckingHeaderProps> = ({ count }) => {
  const { t } = useTranslation();
  const { initialLoad } = useResultGroupsItems();

  const onClearFilters = useCallback(() => {
    initialLoad('full');
  }, [initialLoad]);

  return (
    <div className='admin-discussion-list-filters'>
      <div className='buttons'>
        <DateFilter />
        <CustomerFilter />
        <NetworkFilter />
      </div>
      <a
        className='count'
        onClick={onClearFilters}
      >
        {t('shown')} {count}
        <img
          alt={'clear filters'}
          src={clearFilters}
        />
      </a>
    </div>
  );
};

export default memo(AdminDiscussionListFilters);
