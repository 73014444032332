// Copyright 2020 @po-polochkam authors & contributors
import type { ClaimResponseType } from 'api/abstractions/dictionary';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { setLoadingClaimResponses, setClaimResponses } from 'store/dictionaryReducer';
import { setLoadingResultGroup } from 'store/resutlsReducer';
import { getCustomerClaimResponses } from 'api/dictionary';
import { acceptClaim, acceptCustomerResponse, declineClaim, declineCustomerResponse } from 'api/results';

export interface UseClaimResponsesInterface {
  acceptClaimDeclining: (customerDailyResultId: string, resultGroupId: string) => Promise<void>;
  acceptClaimResponse: (customerDailyResultId: string, resultGroupId: string) => Promise<void>;
  claimResponses: ClaimResponseType[];
  declineClaimDeclining: (customerDailyResultId: string, resultGroupId: string) => Promise<void>;
  declineClaimResponse: (customerDailyResultId: string, resultGroupId: string, customerClaimResponseId: string) => Promise<void>;
  fetchClaimResponses: () => void;
}

export default function useClaimResponses (): UseClaimResponsesInterface {
  const dispatch = useAppDispatch();
  const claimResponses = useSelector((state: RootState) => state.dictionary.claimResponses);

  const acceptClaimResponse = useCallback(async (customerDailyResultId: string, resultGroupId: string) => {
    dispatch(setLoadingResultGroup(true));
    await acceptCustomerResponse(customerDailyResultId, resultGroupId);
    dispatch(setLoadingResultGroup(false));
  }, [dispatch]);

  const declineClaimResponse = useCallback(async (customerDailyResultId: string, resultGroupId: string, customerClaimResponseId: string) => {
    dispatch(setLoadingResultGroup(true));
    await declineCustomerResponse(customerDailyResultId, resultGroupId, customerClaimResponseId);
    dispatch(setLoadingResultGroup(false));
  }, [dispatch]);

  const acceptClaimDeclining = useCallback(async (customerDailyResultId: string, resultGroupId: string) => {
    dispatch(setLoadingResultGroup(true));
    await acceptClaim(customerDailyResultId, resultGroupId);
    dispatch(setLoadingResultGroup(false));
  }, [dispatch]);

  const declineClaimDeclining = useCallback(async (customerDailyResultId: string, resultGroupId: string) => {
    dispatch(setLoadingResultGroup(true));
    await declineClaim(customerDailyResultId, resultGroupId);
    dispatch(setLoadingResultGroup(false));
  }, [dispatch]);

  const fetchClaimResponses = useCallback(async () => {
    dispatch(setLoadingClaimResponses(true));
    const data = await getCustomerClaimResponses() as { count: number, items: ClaimResponseType[] };

    dispatch(setLoadingClaimResponses(false));
    dispatch(setClaimResponses(data.items));
  }, [dispatch]);

  return {
    acceptClaimDeclining,
    acceptClaimResponse,
    claimResponses,
    declineClaimDeclining,
    declineClaimResponse,
    fetchClaimResponses
  };
}
