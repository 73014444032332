// Copyright 2020 @po-polochkam authors & contributors
import moment from 'moment';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { yandex } from 'app-config';
import ButtonCommon from 'uiKit/Button';
import TabsCommon from 'uiKit/TabsCommon';
import ResultModalTabsList from 'components/ResultModalTabsList';
import useResult from 'hooks/useResult';
import useFinalizeVerification from 'hooks/useFinalizeVerification';
import magnit from '../../assets/svg/magnit.svg';
import './styles.less';

interface ResultModalContentProps {
  toggleResultModal: (isOpen: boolean) => void;
}

const ResultModalContent: React.FC<ResultModalContentProps> = ({ toggleResultModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { resultId } = useParams() as unknown as { resultId: string };
  const { fetchResult, selectedResult } = useResult(resultId);
  const { finalizeResultVerification } = useFinalizeVerification();

  const cityName: string = selectedResult?.shopAddress?.cityName || '';
  const regionAreaName: string = selectedResult?.shopAddress?.regionAreaName || '';
  const streetName = selectedResult?.shopAddress?.street || '';
  const address = [streetName, cityName, regionAreaName].join(', ');

  const finalizeVerification = useCallback(() => {
    finalizeResultVerification(resultId);
    toggleResultModal(false);
  }, [finalizeResultVerification, toggleResultModal, resultId]);

  const onChangeTab = useCallback((key: string) => {
    console.log('onChangeTab', key);
  }, []);

  useEffect(() => {
    if (location.hash === `#/home/results-verification/checking/result-modal/${resultId}`) {
      history.push(`/home/results-verification/checking/result-modal/${resultId}/layout`);
    }
  }, [history, resultId]);

  useEffect(() => {
    void fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='result-modal-content'>
      <div className='result-modal-content--header'>
        <div className='name'>
          <img
            alt={selectedResult?.tradeNetwork?.name}
            src={selectedResult?.tradeNetwork?.icon || magnit}
          />
          {selectedResult?.tradeNetwork?.name}
        </div>
        <div className='description'>
          <a
            className='address'
            href={`${yandex}${address}`}
            rel='noreferrer nooperer'
            target='_blank'
          >{address}</a>
          <span className='date'>{moment(selectedResult?.date, 'YYYY-MM-DD').format('DD MMMM, YYYY г.')}</span>
        </div>
        <ButtonCommon
          onClick={finalizeVerification}
          size='large'
          type='primary'
        >
          {(t('finalize verification') || '').toString()}
        </ButtonCommon>
      </div>
      <TabsCommon
        onChangeTab={onChangeTab}
        tabs={ResultModalTabsList(resultId)}
        tabsPath={`/home/results-verification/checking/result-modal/${resultId}/`}
      />
    </div>
  );
};

export default memo(ResultModalContent);
