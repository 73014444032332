// Copyright 2020 @po-polochkam authors & contributors
import type { ResultMistakeType, SmallProduct } from 'api/abstractions/dictionary';
import type { ProductCategoryType } from 'api/abstractions/result';

import React, { memo, ReactNode, useCallback, useEffect, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import CheckboxCommon from 'uiKit/Checkbox/CheckboxCommon';
import ButtonCommon from 'uiKit/Button';
import useResultMistakes from 'hooks/useResultMistakes';

import rightArrow from 'assets/svg/rightArrow.svg';
import './styles.less';

const { TextArea } = Input;

export type ProductType = {
  id: string | number;
  name: string;
}

export type ErrorType = {
  id: string;
  name: string;
  hasProducts: boolean;
  mistakeId: string;
}

export type ErrorItemType = {
  description?: string;
  id: string | number;
  errors: ErrorType[];
  name: string;
}

interface ErrorContentProps {
  category: ProductCategoryType;
  clearSelector: () => void;
  fetchResult: () => Promise<boolean>;
  hasMistake: boolean;
  menu?: ReactNode;
  onAcceptProducts: () => void;
  ownErrorText: string;
  reportResultId: string;
  resultId: string;
  selectedError?: ErrorType;
  selectedErrorType?: ErrorItemType;
  selectedProducts: { [key: string]: ProductType };
  setOwnErrorText: (text: string) => void;
  setSelectedError: (error: ErrorType | undefined) => void;
  setSelectedErrorType: (error: ErrorItemType | undefined) => void;
  setSelectedProducts: (selectedProducts: { [key: string]: ProductType }) => void;
}

const ErrorSelectorContent: React.FC<ErrorContentProps> = (props, ref) => {
  const {
    category,
    clearSelector,
    fetchResult,
    hasMistake,
    onAcceptProducts,
    ownErrorText,
    reportResultId,
    resultId,
    selectedError,
    selectedErrorType,
    selectedProducts,
    setOwnErrorText,
    setSelectedError,
    setSelectedErrorType,
    setSelectedProducts
  } = props;
  const { t } = useTranslation();
  const [errorTypes, setErrorTypes] = useState<ErrorItemType[]>([]);
  const [errorAlreadySet, setErrorAlreadySet] = useState<boolean>(false);
  const { addCustomMistake, addMistake, fetchResultMistakeByType, fetchResultMistakeTypes, fetchResultProducts, mistakesByType, resultMistakeTypes, resultProducts } = useResultMistakes();

  const isErrorChecked = useCallback((error: ErrorType): boolean => {
    return !error.hasProducts && !!(selectedError && selectedError.id === error.id);
  }, [selectedError]);

  const isProductChecked = useCallback((product: ProductType): boolean => {
    return !!selectedProducts[product.id];
  }, [selectedProducts]);

  const onErrorClick = useCallback(async (error: ErrorType, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    // мы не можем изменять ошибку, мы только ее показываем
    if (selectedError?.mistakeId) {
      return;
    }

    setSelectedError(error);

    if (!error.hasProducts) {
      await addMistake(resultId, reportResultId, error.id.toString(), [...Object.keys(selectedProducts)]);
      await fetchResult();
      clearSelector();
    }
  }, [addMistake, clearSelector, fetchResult, setSelectedError, selectedError, selectedProducts, resultId, reportResultId]);

  const onProductClick = useCallback((product: ProductType) => {
    if (selectedError?.mistakeId) {
      return;
    }

    if (selectedProducts[product.id]) {
      const newSelectedProducts = { ...selectedProducts };

      delete newSelectedProducts[product.id];
      setSelectedProducts(newSelectedProducts);
    } else {
      setSelectedProducts(({ ...selectedProducts, [product.id]: product }));
    }
  }, [selectedError, selectedProducts, setSelectedProducts]);

  const onErrorTypeClick = useCallback((errorType: ErrorItemType, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedErrorType(errorType);
    setErrorAlreadySet(false);
  }, [setSelectedErrorType]);

  const onSetOwnErrorText = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOwnErrorText(e.target.value);
  }, [setOwnErrorText]);

  const saveSelectedError = useCallback(async () => {
    if (selectedErrorType && selectedError && selectedErrorType.id !== 'own') {
      await addMistake(resultId, reportResultId, selectedError.id.toString(), [...Object.keys(selectedProducts)]);
    }

    if (selectedErrorType && selectedErrorType.id === 'own') {
      await addCustomMistake(resultId, reportResultId, ownErrorText, [...Object.keys(selectedProducts)]);
    }

    onAcceptProducts();
  }, [addCustomMistake, addMistake, onAcceptProducts, ownErrorText, selectedErrorType, selectedError, selectedProducts, reportResultId, resultId]);

  const onAcceptErrorText = useCallback(() => {
    setErrorAlreadySet(true);
    setSelectedError({ hasProducts: true, id: 'own', mistakeId: '', name: ownErrorText });
  }, [setSelectedError, ownErrorText]);

  const goBack = useCallback(() => {
    if (selectedErrorType?.id) {
      if (selectedError?.id) {
        setSelectedError(undefined);
      } else {
        setSelectedErrorType(undefined);
      }
    }
  }, [selectedErrorType, selectedError, setSelectedError, setSelectedErrorType]);

  useEffect(() => {
    if (resultMistakeTypes) {
      setErrorTypes([...resultMistakeTypes.map((item: ResultMistakeType) => ({ ...item, errors: [] }) as ErrorItemType), { errors: [], id: 'own', name: 'Свой вариант' }]);
    }
  }, [resultMistakeTypes]);

  useEffect(() => {
    fetchResultMistakeTypes();
  }, [fetchResultMistakeTypes]);

  useEffect(() => {
    if (selectedErrorType) {
      if (selectedErrorType.id !== 'own') {
        fetchResultMistakeByType(selectedErrorType.id.toString());
      }
    }
  }, [fetchResultMistakeByType, selectedErrorType]);

  useEffect(() => {
    if (selectedError && resultId && category.id && selectedError.hasProducts) {
      fetchResultProducts(resultId, category.id);

      if (Object.keys(selectedProducts).length && selectedError.id === 'own') {
        setErrorAlreadySet(true);
      }
    }
  }, [category, fetchResultProducts, selectedError, selectedProducts, resultId]);

  useEffect(() => {
    if (selectedError?.id === 'own') {
      setOwnErrorText(selectedError.name);
    }
  }, [setOwnErrorText, selectedError]);

  return (
    <div
      className='error-selector-content'
      ref={ref}
    >
      { (!hasMistake && selectedErrorType?.id) && (
        <div
          className='arrow-back'
          onClick={goBack}
        >
          <svg fill='none'
            height='11'
            viewBox='0 0 6 11'
            width='6'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1.5L5.0001 5.5001'
              stroke='#013CD2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'/>
            <path d='M1 9.5L5.0001 5.4999'
              stroke='#013CD2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'/>
          </svg>
          <span>{t('go back')}</span>
        </div>
      )}
      { (!selectedErrorType?.id && !selectedError?.id) && (
        <div className='selector-header'>1. {t('select error type')}</div>
      )}
      { selectedErrorType?.id && selectedErrorType?.id !== 'own' && !selectedError?.id && (
        <div className='selector-header'>2. {t('select error type error')}</div>
      )}
      { selectedErrorType?.id && selectedErrorType?.id === 'own' && !selectedError?.id && (
        <div className='selector-header'>{t('own version')}</div>
      )}
      <div className='error-selector-content-inner'>
        { !selectedErrorType?.id && errorTypes.map((errorType: ErrorItemType) => (
          <div
            className='error-type'
            key={`error-type-${errorType.id}`}
          >
            <div
              className={`item ${selectedErrorType ? 'selected' : ''}`}
              onClick={onErrorTypeClick.bind(this, errorType)}
            >
              <span>{errorType.name}</span>
              <img
                alt='right-arrow'
                src={rightArrow}
              />
            </div>
          </div>
        ))}
        <div className='error-type'>
          { selectedErrorType?.id === 'own' && (
            <div
              className={'error-type--error'}
              key='error-own'
            >
              <div className='own selected'>
                { (errorAlreadySet && selectedError?.name) && (
                  <div className='item'>
                    <span>{selectedError.name}</span>
                  </div>
                )}
                { !errorAlreadySet && (
                  <>
                    <TextArea
                      disabled={!!selectedError?.id}
                      onChange={onSetOwnErrorText}
                      placeholder={(t('write short error') || '').toString()}
                      rows={7}
                      value={ownErrorText}
                    />
                    <ButtonCommon
                      block
                      disabled={!ownErrorText.length || !!selectedError?.id}
                      onClick={onAcceptErrorText}
                      size='large'
                      type='primary'
                    >
                      {(t('next') || '').toString()}
                    </ButtonCommon>
                  </>
                )}
              </div>

              { errorAlreadySet && resultProducts.map((product: SmallProduct) => (
                <div
                  className='error-type--error--product'
                  key={`product-${product.id}`}
                >
                  <CheckboxCommon
                    checked={isProductChecked(product)}
                    name={`${product.id}`}
                    onChange={onProductClick.bind(null, product)}
                  >
                    {product.name}
                  </CheckboxCommon>
                </div>
              ))}
            </div>
          )}
          { (selectedErrorType?.id && selectedErrorType?.id !== 'own') && mistakesByType.map((error: ErrorType) => (
            <div
              className={`error-type--error ${isErrorChecked(error) ? 'checked' : ''}`}
              key={`error-${error.id}`}
            >
              { ((!selectedError?.id || selectedError?.id === error.id) && !selectedError?.hasProducts) && (
                <div
                  className={`item ${(selectedError?.id === error.id) ? 'active' : ''}`}
                  onClick={onErrorClick.bind(this, error)}
                >
                  <span>{error.name}</span>
                  {(error.hasProducts && (!selectedError || selectedError.id !== error.id)) && (
                    <img
                      alt='right-arrow'
                      src={rightArrow}
                    />
                  )}
                </div>
              )}
              { (selectedError?.id === error.id && selectedError?.hasProducts) && resultProducts.map((product: SmallProduct) => (
                <div
                  className='error-type--error--product'
                  key={`product-${product.id}`}
                >
                  <CheckboxCommon
                    checked={isProductChecked(product)}
                    name={`${product.id}`}
                    onChange={onProductClick.bind(null, product)}
                  >
                    {product.name}
                  </CheckboxCommon>
                </div>
              ))}
            </div>
          ))}
        </div>
        { (selectedError?.id && selectedError.hasProducts) && (
          <ButtonCommon
            block
            disabled={(!Object.keys(selectedProducts).length && selectedErrorType?.id !== 'own') || !!selectedError?.mistakeId}
            onClick={saveSelectedError}
            size='large'
            type='primary'
          >
            {(t('accept') || '').toString()}
          </ButtonCommon>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default memo(forwardRef<HTMLDivElement, ErrorContentProps>(ErrorSelectorContent));
