// Copyright 2020 @po-polochkam authors & contributors

import { yandex } from 'app-config';
import moment from 'moment';
import ButtonCommon from 'uiKit/Button';
import React, { memo, useCallback } from 'react';
import useClaimResponses from 'hooks/useClaimResponses';
import { useTranslation } from 'react-i18next';
import { ReportResultGroupEnriched } from 'api/abstractions/result';
import magnit from 'assets/svg/magnit.svg';

import './styles.less';

interface Props {
  address: string;
  selectedResultGroup: ReportResultGroupEnriched | undefined;
  toggleResultModal?: (isOpen: boolean) => void;
}

const CustomerModalHeader: React.FC<Props> = (props: Props) => {
  const { address, selectedResultGroup, toggleResultModal } = props;
  const { t } = useTranslation();
  const { acceptClaimDeclining, declineClaimDeclining } = useClaimResponses();

  const accept = useCallback(async () => {
    if (selectedResultGroup) {
      await acceptClaimDeclining(selectedResultGroup.customerDailyResultId, selectedResultGroup.id);
      toggleResultModal && toggleResultModal(false);
    }
  }, [acceptClaimDeclining, selectedResultGroup, toggleResultModal]);

  const decline = useCallback(async () => {
    if (selectedResultGroup) {
      await declineClaimDeclining(selectedResultGroup.customerDailyResultId, selectedResultGroup.id);
      toggleResultModal && toggleResultModal(false);
    }
  }, [declineClaimDeclining, selectedResultGroup, toggleResultModal]);

  return (
    <div className='discussion-modal-content--header customer'>
      <div className='name'>
        <img
          alt={selectedResultGroup?.customerDailyResult?.tradeNetwork?.name}
          src={selectedResultGroup?.customerDailyResult?.tradeNetwork?.icon || magnit}
        />
        {selectedResultGroup?.customerDailyResult?.tradeNetwork?.name}
      </div>
      <div className='description'>
        <a
          className='address'
          href={`${yandex}${address}`}
          rel='noreferrer nooperer'
          target='_blank'
        >{address}</a>
        <span className='date'>{moment(selectedResultGroup?.customerDailyResult?.date, 'YYYY-MM-DD').format('DD MMMM, YYYY г.')}</span>
      </div>
      <div className='service-decision'>
        <div className='service-decision--text'>
          <small>{t('service decision')}</small>
          { selectedResultGroup?.claimApproval === 'accepted' && (
            <span>{t('service accepted the claim')}</span>
          )}
          <span>{selectedResultGroup?.customerClaimResponse.text}</span>
        </div>
        { (selectedResultGroup?.claimApproval === 'declined' && selectedResultGroup?.claimStatus === 'active') && (
          <>
            <ButtonCommon
              onClick={accept}
              size='large'
              type='primary'
            >
              {(t('accept') || '').toString()}
            </ButtonCommon>
            <ButtonCommon
              onClick={decline}
              size='large'
              type='text'
            >
              {(t('decline') || '').toString()}
            </ButtonCommon>
          </>
        )}
      </div>
      { (selectedResultGroup?.claimStatus === 'revoked' || selectedResultGroup?.claimStatus === 'dispute') && (
        <div className='service-decision'>
          <div className='service-decision--text'>
            <small>{t('customer`s response')}</small>
            { selectedResultGroup?.claimStatus === 'revoked' && <span>{t('withdraw the claim')}</span>}
            { selectedResultGroup?.claimStatus === 'dispute' && <span>{t('left the claim intact')}</span>}
          </div>
          <div />
        </div>
      )}
    </div>
  );
};

export default memo(CustomerModalHeader);
