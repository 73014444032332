// Copyright 2020 @po-polochkam authors & contributors

export type ThemeType = {
  bordersSecondary: string;
  lightBackgroundColor: string;
}

export const theme: ThemeType = {
  bordersSecondary: '1px solid #A7BCF4',
  lightBackgroundColor: '#F3F3F4'
};
