// Copyright 2020 @po-polochkam authors & contributors

import { useAppDispatch } from 'store/store';
import { useCallback } from 'react';

import { setLoadingResult, setLoadingResults } from 'store/resutlsReducer';
import { finishReviewing } from 'api/results';

export interface UseResultInterface {
  finalizeResultVerification: (resultId: string) => void;
}

export function useFinalizeVerification (): UseResultInterface {
  const dispatch = useAppDispatch();

  const finalizeResultVerification = useCallback(async (resultId: string) => {
    // вызов апи со списком результатов проверки
    if (!resultId) {
      return;
    }

    dispatch(setLoadingResult(true));
    dispatch(setLoadingResults({ loading: true }));
    const data: { error?: any } = await finishReviewing(resultId);

    if (data.error) {
      dispatch(setLoadingResult(false));
      dispatch(setLoadingResults({ loading: false }));

      return;
    }

    dispatch(setLoadingResult(false));
    dispatch(setLoadingResults({ loading: false }));
  }, [dispatch]);

  return {
    finalizeResultVerification
  };
}

export default useFinalizeVerification;
