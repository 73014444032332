// Copyright 2020 @po-polochkam authors & contributors
import type { ReportResultGroupEnriched, ReportResultType } from 'api/abstractions/result';

import { RootState, useAppDispatch } from 'store/store';
import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { setLoadingResultGroup, setSelectedResultGroup } from 'store/resutlsReducer';
import { fetchReportSampleImage, getResultGroup } from 'api/results';
import { ResultProduct, SmallProduct } from 'api/abstractions/dictionary';
import { getProductsByResult } from 'api/dictionary';

export interface UseResultInterface {
  fetchProductsByResult: (customerDailyResultId: string) => Promise<void>;
  fetchResultGroup: () => Promise<boolean>;
  loadingResultGroup: boolean;
  resultProducts: SmallProduct[];
  selectedResultGroup: ReportResultGroupEnriched | undefined;
}

const DEFAULT_IMAGES_VALUE: ReportResultType[] = [];

/**
 * Возвращает список ссылок (url) на изображения образцов отчетов
 * @param resultId - id результата визита
 * @param reports - список отчетов, для которых получаем изображение
 * */
export const useReportSamplesImages = (resultId: string | undefined, reports: ReportResultType[] | undefined): Array<string | null> => {
  const [images, setImages] = useState<Array<string | null>>([]);

  if (!reports || !resultId) {
    reports = DEFAULT_IMAGES_VALUE;
  }

  useEffect(() => {
    if (!reports) {
      return;
    }

    const promises = reports.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return fetchReportSampleImage(resultId, item?.id);
    });

    void (async function () {
      const images = await Promise.all(promises);

      setImages(images);
    })();
  }, [resultId, reports]);

  return images;
};

export function useResultGroup (resultId: string): UseResultInterface {
  const dispatch = useAppDispatch();
  const [resultProducts, setResultProducts] = useState<SmallProduct[]>([]);
  const selectedResultGroup = useSelector((state: RootState) => state.results.selectedResultGroup);
  const loadingResultGroup = useSelector((state: RootState) => state.results.loadingResultGroup);

  const fetchProductsByResult = useCallback(async (customerDailyResultId: string): Promise<void> => {
    const data: ResultProduct = await getProductsByResult(customerDailyResultId, { page: 0, perPage: 300 }) as ResultProduct;

    setResultProducts(data.skus);
  }, []);

  const fetchResultGroup = useCallback(async (): Promise<boolean> => {
    dispatch(setSelectedResultGroup(undefined));

    if (!resultId) {
      return false;
    }

    dispatch(setLoadingResultGroup(true));
    const data: { error?: any } = await getResultGroup(resultId);

    if (data.error) {
      dispatch(setLoadingResultGroup(false));

      return false;
    }

    dispatch(setSelectedResultGroup(data as ReportResultGroupEnriched));
    dispatch(setLoadingResultGroup(false));

    return true;
  }, [dispatch, resultId]);

  return {
    fetchProductsByResult,
    fetchResultGroup,
    loadingResultGroup,
    resultProducts,
    selectedResultGroup
  };
}

export default useResultGroup;
