// Copyright 2020 @po-polochkam authors & contributors

import type { DiscussionFilter, Filter, ResultType, ReportResultEnriched, ReportResultGroup, ReportResultGroupEnriched } from 'api/abstractions/result';
import type { CustomerType, TradeNetworkType } from 'api/abstractions/dictionary';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegionAreaType } from 'api/abstractions/dictionary';

//  [date: string]: { [id: string] : ResultType };
export interface ResultsMapInterface {
  [filter: string] : { [page: string]: Array<ResultType> };
}

export interface CountInterface {
  [key: string]: number;
}

export interface ResultsInitialStateInterface {
  count: CountInterface;
  groupsCount: number;
  loadingResult: boolean;
  loadingResultGroup: boolean;
  loadingResultGroups: boolean;
  loadingResults: boolean;
  page: number;
  perPage: number;
  resultGroups: Array<ReportResultGroup>;
  results: ResultsMapInterface;
  resultsAreas: RegionAreaType[];
  resultsCities: string[];
  resultsCustomers: {[key: string]: CustomerType},
  resultsDates: [string, string] | null,
  resultsNetworks: {[key: string]: TradeNetworkType},
  resultsRegions: string[];
  resultsSearch: string | undefined;
  selectedResult: ReportResultEnriched | undefined;
  selectedResultGroup: ReportResultGroupEnriched | undefined;
  viewedDiscussionResults: { [key: string]: boolean };
  viewedReports: { [key: string]: boolean };
  viewedResults: { [key: string]: boolean };
}

export function getFromStorage (): { [key: string]: boolean } {
  try {
    return JSON.parse(localStorage.getItem('viewedDiscussionResults') as string) as { [key: string]: boolean } || {};
  } catch (e) {
    console.log('viewedDiscussionResults parse error');
  }

  return {};
}

const initialState: ResultsInitialStateInterface = {
  count: {},
  groupsCount: 0,
  loadingResult: false,
  loadingResultGroup: false,
  loadingResultGroups: false,
  loadingResults: false,
  page: 1,
  perPage: 10,
  resultGroups: [],
  results: {},
  resultsAreas: [],
  resultsCities: [],
  resultsCustomers: {},
  resultsDates: null,
  resultsNetworks: {},
  resultsRegions: [],
  resultsSearch: undefined,
  selectedResult: undefined,
  selectedResultGroup: undefined,
  viewedDiscussionResults: getFromStorage(),
  viewedReports: {},
  viewedResults: {}
};

const resultsSlice = createSlice({
  initialState,
  name: 'results',
  reducers: {
    fullReset: () => ({
      ...initialState,
      viewedDiscussionResults: getFromStorage()
    }),
    lightReset: (state: ResultsInitialStateInterface) => ({
      ...initialState,
      resultsAreas: state.resultsAreas,
      resultsCities: state.resultsCities,
      resultsCustomers: state.resultsCustomers,
      resultsDates: state.resultsDates,
      resultsNetworks: state.resultsNetworks,
      resultsRegions: state.resultsRegions,
      resultsSearch: state.resultsSearch,
      viewedDiscussionResults: getFromStorage(),
      viewedReports: state.viewedResults,
      viewedResults: state.viewedResults
    }),
    receiveResultGroups (state: ResultsInitialStateInterface, action: PayloadAction<{ count: number, items: Array<ReportResultGroup>, page: number, filter: DiscussionFilter }>) {
      const { count, items } = action.payload;

      state.groupsCount = count;
      state.resultGroups = items;
      state.loadingResults = false;
    },
    receiveResults (state: ResultsInitialStateInterface, action: PayloadAction<{ count: number, items: Array<ResultType>, page: number, filter: Filter }>) {
      const { count, filter, items, page } = action.payload;

      const statusFilter: string = filter.status;

      state.count[statusFilter] = count;

      if (!state.results[statusFilter]) {
        state.results[statusFilter] = {};
      }

      state.results[statusFilter][page] = items;
      state.loadingResults = false;
    },
    reset: (state: ResultsInitialStateInterface) => ({
      ...initialState,
      viewedReports: state.viewedResults,
      viewedResults: state.viewedResults
    }),
    setLoadingResult (state: ResultsInitialStateInterface, action: PayloadAction<boolean>) {
      state.loadingResult = action.payload;
    },
    setLoadingResultGroup (state: ResultsInitialStateInterface, action: PayloadAction<boolean>) {
      state.loadingResultGroup = action.payload;
    },
    setLoadingResultGroups (state: ResultsInitialStateInterface, action: PayloadAction<{ loading: boolean }>) {
      const { loading } = action.payload;

      state.loadingResultGroups = loading;
    },
    setLoadingResults (state: ResultsInitialStateInterface, action: PayloadAction<{ loading: boolean }>) {
      const { loading } = action.payload;

      state.loadingResults = loading;
    },
    setPage (state: ResultsInitialStateInterface, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setPerPage (state: ResultsInitialStateInterface, action: PayloadAction<number>) {
      state.perPage = action.payload;
      // set page as default
      state.page = initialState.page;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
    },
    setResultsAreas (state: ResultsInitialStateInterface, action: PayloadAction<RegionAreaType[]>) {
      state.page = 1;
      state.resultsAreas = action.payload;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
    },
    setResultsCities (state: ResultsInitialStateInterface, action: PayloadAction<string[]>) {
      state.page = 1;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
      state.resultsCities = action.payload;
    },
    setResultsCustomers (state: ResultsInitialStateInterface, action: PayloadAction<{ [key: string]: CustomerType }>) {
      state.page = 1;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
      state.resultsCustomers = action.payload;
    },
    setResultsDates (state: ResultsInitialStateInterface, action: PayloadAction<[string, string] | null>) {
      state.page = 1;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
      state.resultsDates = action.payload;
    },
    setResultsNetwork (state: ResultsInitialStateInterface, action: PayloadAction<{ [key: string]: TradeNetworkType }>) {
      state.page = 1;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
      state.resultsNetworks = action.payload;
    },
    setResultsRegions (state: ResultsInitialStateInterface, action: PayloadAction<string[]>) {
      state.page = 1;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
      state.resultsRegions = action.payload;
    },
    setResultsSearch (state: ResultsInitialStateInterface, action: PayloadAction<string>) {
      state.page = 1;
      state.results = initialState.results;
      state.resultGroups = initialState.resultGroups;
      state.resultsSearch = action.payload;
    },
    setSelectedResult (state: ResultsInitialStateInterface, action: PayloadAction<ReportResultEnriched | undefined>) {
      state.selectedResult = action.payload;
    },
    setSelectedResultGroup (state: ResultsInitialStateInterface, action: PayloadAction<ReportResultGroupEnriched | undefined>) {
      state.selectedResultGroup = action.payload;
    },
    setViewedDiscussionResults (state: ResultsInitialStateInterface, action: PayloadAction<{ [key: string]: boolean }>) {
      localStorage.setItem('viewedDiscussionResults', JSON.stringify(action.payload));
      state.viewedDiscussionResults = getFromStorage();
    },
    setViewedReports (state: ResultsInitialStateInterface, action: PayloadAction<{ [key: string]: boolean }>) {
      state.viewedReports = action.payload;
    },
    setViewedResults (state: ResultsInitialStateInterface, action: PayloadAction<{ [key: string]: boolean }>) {
      state.viewedResults = action.payload;
    }
  }
});

export const {
  fullReset,
  lightReset,
  receiveResultGroups,
  receiveResults,
  reset,
  setLoadingResult,
  setLoadingResultGroup,
  setLoadingResultGroups,
  setLoadingResults,
  setPage,
  setPerPage,
  setResultsAreas,
  setResultsCities,
  setResultsCustomers,
  setResultsDates,
  setResultsNetwork,
  setResultsRegions,
  setResultsSearch,
  setSelectedResult,
  setSelectedResultGroup,
  setViewedDiscussionResults,
  setViewedReports,
  setViewedResults
} = resultsSlice.actions;

export default resultsSlice.reducer;
