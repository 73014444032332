// Copyright 2020 @po-polochkam authors & contributors
import { ResultType } from 'api/abstractions/result';

import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setResultsSearch } from 'store/resutlsReducer';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';

import CheckboxCommon from 'uiKit/Checkbox/CheckboxCommon';

import SearchInput from 'components/SearchInput';
import NetworkFilter from 'components/NetworkFilter';
import DateFilter from 'components/DateFilter';
import clearFilters from 'assets/svg/clearFilters.svg';
import useResultsItems from 'hooks/useResultsItems';

import './styles.less';

interface ListCheckingHeaderProps {
  allChecked: boolean;
  count: number;
  resultsMappedByDate: { [date: string]: { [id: string] : ResultType } };
  selectedResults: { [id: string] : ResultType };
  setAllChecked: (allChecked: boolean | ((prevState: boolean) => boolean)) => void;
  setSelectedResults: (items: { [id: string] : ResultType }) => void;
}

const ResultsListHeader: React.FC<ListCheckingHeaderProps> = (props) => {
  const { allChecked, count, resultsMappedByDate, selectedResults, setAllChecked, setSelectedResults } = props;
  const { initialLoad } = useResultsItems();
  const addressOrNameSearch = useSelector((state: RootState) => state.results.resultsSearch);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // далее здесь будет вызов апи для выбора всех результатов на бэке
  const selectAllResults = useCallback(() => {
    const allResultsMap: { [id: string] : ResultType } = {};

    Object.values(resultsMappedByDate).forEach((resultDateMap) => {
      Object.values(resultDateMap).forEach((resultItem: ResultType) => {
        allResultsMap[resultItem.id] = resultItem;
      });
    });

    setSelectedResults(allResultsMap);
  }, [setSelectedResults, resultsMappedByDate]);

  const onChangeAllChecked = useCallback(() => {
    // если у нас уже есть что-то выбранное - отменяем все
    if (Object.keys(selectedResults).length) {
      setSelectedResults({});
      setAllChecked(false);
    } else {
      selectAllResults();
      setAllChecked((prevState: boolean) => !prevState);
    }
  }, [selectAllResults, selectedResults, setAllChecked, setSelectedResults]);

  const setAddressOrNameSearch = useCallback((search) => {
    dispatch(setResultsSearch(search));
  }, [dispatch]);

  const onClearFilters = useCallback(() => {
    initialLoad('full');
  }, [initialLoad]);

  useEffect(() => {
    if (allChecked) {
      // @todo на очень больших списках если будет подтормаживание, перейду на маппинг постранично
      selectAllResults();
    }
  }, [allChecked, selectAllResults]);

  // адреса будут приходить по апи, поэтому здесь они не локализованы
  return (
    <div className='results-list--header'>
      <CheckboxCommon
        checked={allChecked}
        indeterminate={!allChecked && !!Object.keys(selectedResults).length}
        name='all'
        onChange={onChangeAllChecked}
      />
      <div className='buttons'>
        <DateFilter />
        <NetworkFilter />
        <SearchInput
          onChange={setAddressOrNameSearch}
          onClearSearch={setAddressOrNameSearch.bind(null, null)}
          placeholder={(t('search store by address or name') || '').toString()}
          value={addressOrNameSearch}
        />
      </div>
      <a
        className='count'
        onClick={onClearFilters}
      >
        {t('shown')} {count}
        <img
          alt={'clear filters'}
          src={clearFilters}
        />
      </a>
    </div>
  );
};

export default memo(ResultsListHeader);
