// Copyright 2021 @po-polochkam authors & contributors
import type { ChatMessage } from '../firebaseStore/entities/chatMessages';
import firebase from 'firebase';
import { useCallback, useEffect, useState } from 'react';

import useIsMountedRef from 'hooks/useIsMountedRef';

export interface ChatFirebaseUnreadInterface {
  unreadMessages: number;
}

export default function useChatFirebaseUnread (chatSourceId: string, firebaseDb: firebase.database.Database | undefined, userId: string): ChatFirebaseUnreadInterface {
  const [chatMessages, setChatMessages] = useState<{ [chatMessageId: string]: ChatMessage }>({});
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const mountedRef = useIsMountedRef();

  const setUnread = useCallback(() => {
    const unreadMessages: ChatMessage[] = Object.values(chatMessages).filter((chatMessage: ChatMessage) => !chatMessage.readAt && chatMessage.userId !== userId);

    mountedRef.current && setUnreadMessages(unreadMessages.length);
  }, [chatMessages, mountedRef, userId]);

  const fetchMessagesById = useCallback(() => {
    if (!firebaseDb) {
      return;
    }

    const starCountRef = firebaseDb.ref(`resultsMessages/${chatSourceId}`);

    starCountRef.on('value', (snapshot) => {
      if (snapshot.val() && mountedRef.current) {
        setChatMessages(snapshot.val());
      }
    });
  }, [chatSourceId, firebaseDb, mountedRef]);

  useEffect(() => {
    setUnread();
  }, [setUnread]);

  useEffect(() => {
    void fetchMessagesById();
  }, [fetchMessagesById]);

  return {
    unreadMessages
  };
}
