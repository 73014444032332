// Copyright 2020 @po-polochkam authors & contributors

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { CityType,
  CustomerType,
  RegionType,
  RegionAreaType,
  ResultMistakeType,
  TradeNetworkType,
  ClaimResponseType } from 'api/abstractions/dictionary';

export type DictionaryInitialStateType = {
  cities: CityType[];
  citiesTerm: string | undefined;
  citiesLoading: boolean;
  claimResponses: ClaimResponseType[];
  claimResponsesLoading: boolean;
  customers: CustomerType[],
  customersLoading: boolean,
  networksLoading: boolean;
  regionAreas: RegionAreaType[];
  regionAreasLoading: boolean;
  regionAreasTerm: string | undefined;
  regions: RegionType[];
  regionsLoading: boolean;
  resultMistakeTypes: ResultMistakeType[];
  selectedRegion: RegionType | null;
  tradeNetworks: TradeNetworkType[];
};

const initialState: DictionaryInitialStateType = {
  cities: [],
  citiesLoading: false,
  citiesTerm: undefined,
  claimResponses: [],
  claimResponsesLoading: false,
  customers: [],
  customersLoading: false,
  networksLoading: false,
  regionAreas: [],
  regionAreasLoading: false,
  regionAreasTerm: undefined,
  regions: [],
  regionsLoading: false,
  resultMistakeTypes: [],
  selectedRegion: null,
  tradeNetworks: []
};

const dictionarySlice = createSlice({
  initialState,
  name: 'dictionary',
  reducers: {
    reset: () => initialState,
    setCities (state: DictionaryInitialStateType, action: PayloadAction<CityType[]>) {
      state.cities = action.payload;
    },
    setCitiesTerm (state: DictionaryInitialStateType, action: PayloadAction<string | undefined>) {
      state.citiesTerm = action.payload;
    },
    setClaimResponses (state: DictionaryInitialStateType, action: PayloadAction<ClaimResponseType[]>) {
      state.claimResponses = action.payload;
    },
    setCustomers (state: DictionaryInitialStateType, action: PayloadAction<CustomerType[]>) {
      state.customers = action.payload;
    },
    setLoadingCities (state: DictionaryInitialStateType, action: PayloadAction<boolean>) {
      state.citiesLoading = action.payload;
    },
    setLoadingClaimResponses (state: DictionaryInitialStateType, action: PayloadAction<boolean>) {
      state.claimResponsesLoading = action.payload;
    },
    setLoadingCustomers (state: DictionaryInitialStateType, action: PayloadAction<boolean>) {
      state.customersLoading = action.payload;
    },
    setLoadingNetworks (state: DictionaryInitialStateType, action: PayloadAction<boolean>) {
      state.networksLoading = action.payload;
    },
    setLoadingRegionAreas (state: DictionaryInitialStateType, action: PayloadAction<boolean>) {
      state.regionAreasLoading = action.payload;
    },
    setLoadingRegions (state: DictionaryInitialStateType, action: PayloadAction<boolean>) {
      state.regionsLoading = action.payload;
    },
    setRegionAreas (state: DictionaryInitialStateType, action: PayloadAction<{ region: RegionType | null, regionAreas: RegionAreaType[] }>) {
      const { region, regionAreas } = action.payload;

      state.regionAreas = regionAreas;
      state.selectedRegion = region;
    },
    setRegionAreasTerm (state: DictionaryInitialStateType, action: PayloadAction<string | undefined>) {
      state.regionAreasTerm = action.payload;
    },
    setRegions (state: DictionaryInitialStateType, action: PayloadAction<RegionType[]>) {
      state.regions = action.payload;
    },
    setResultMistakeTypes (state: DictionaryInitialStateType, action: PayloadAction<ResultMistakeType[]>) {
      state.resultMistakeTypes = action.payload;
    },
    setTradeNetworks (state: DictionaryInitialStateType, action: PayloadAction<TradeNetworkType[]>) {
      state.tradeNetworks = action.payload;
    }
  }
});

export const {
  reset,
  setCities,
  setCitiesTerm,
  setClaimResponses,
  setCustomers,
  setLoadingCities,
  setLoadingClaimResponses,
  setLoadingCustomers,
  setLoadingNetworks,
  setLoadingRegionAreas,
  setLoadingRegions,
  setRegionAreas,
  setRegionAreasTerm,
  setRegions,
  setResultMistakeTypes,
  setTradeNetworks
} = dictionarySlice.actions;

export default dictionarySlice.reducer;
