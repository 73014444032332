// Copyright 2020 @po-polochkam authors & contributors
import request from './request';

export const merchApi = 'merch/v1/';

export const loginUser = ({ username, password }: { username: string, password: string }) => request({
  url: `${merchApi}auth/token`,
  method: 'POST',
  data: {
    client_id: 'customer-lk-front',
    client_secret: 'customer_lk_secret',
    grant_type: 'password',
    username,
    password,
  }
});

export const uploadFile = async (userId: string, formData: any, contentType: string) => {
  const data = await request({
    headers: {
      'Content-Type': contentType
    },
    url: `/chat/v1/users/${userId}/files`,
    method: 'POST',
    data: formData
  });

  console.log('data', data);

  return data.data;
}