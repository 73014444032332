// Copyright 2020 @po-polochkam authors & contributors
import type { ReportResultEnriched } from 'api/abstractions/result';
import { RootState, useAppDispatch } from 'store/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { setLoadingResult, setSelectedResult } from 'store/resutlsReducer';
import { getResult } from 'api/results';

export interface UseResultInterface {
  fetchResult: () => Promise<boolean>;
  loadingResult: boolean;
  selectedResult: ReportResultEnriched | undefined
}

export function useResult (resultId: string): UseResultInterface {
  const dispatch = useAppDispatch();
  const selectedResult = useSelector((state: RootState) => state.results.selectedResult);
  const loadingResult = useSelector((state: RootState) => state.results.loadingResult);

  const fetchResult = useCallback(async (): Promise<boolean> => {
    dispatch(setSelectedResult(undefined));

    if (!resultId) {
      return false;
    }

    dispatch(setLoadingResult(true));
    const data: { error?: any } = await getResult(resultId);

    if (data.error) {
      dispatch(setLoadingResult(false));

      return false;
    }

    dispatch(setSelectedResult(data as ReportResultEnriched));
    dispatch(setLoadingResult(false));

    return true;
  }, [dispatch, resultId]);

  return {
    fetchResult,
    loadingResult,
    selectedResult
  };
}

export default useResult;
