// Copyright 2020 @po-polochkam authors & contributors
import type { CustomerType } from 'api/abstractions/dictionary';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { setLoadingCustomers, setCustomers } from 'store/dictionaryReducer';
import { getCustomers } from 'api/dictionary';

export interface UseCustomersInterface {
  customers: CustomerType[];
  fetchCustomers: () => void;
}

export default function useCustomers (): UseCustomersInterface {
  const dispatch = useAppDispatch();
  const customers = useSelector((state: RootState) => state.dictionary.customers);

  const fetchCustomers = useCallback(async () => {
    dispatch(setLoadingCustomers(true));
    const data = await getCustomers() as { count: number, items: CustomerType[] };

    dispatch(setLoadingCustomers(false));
    dispatch(setCustomers(data.items));
  }, [dispatch]);

  return {
    customers,
    fetchCustomers
  };
}
