// Copyright 2020 @po-polochkam authors & contributors
import type { TradeNetworkType } from 'api/abstractions/dictionary';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { setLoadingNetworks, setTradeNetworks } from 'store/dictionaryReducer';
import { getTradeNetworks } from 'api/dictionary';

export interface UseTradeNetworksInterface {
  fetchTradeNetworks: () => void;
  tradeNetworks: TradeNetworkType[];
}

export default function useTradeNetworks (): UseTradeNetworksInterface {
  const dispatch = useAppDispatch();
  const tradeNetworks = useSelector((state: RootState) => state.dictionary.tradeNetworks);

  const fetchTradeNetworks = useCallback(async () => {
    dispatch(setLoadingNetworks(true));
    const data = await getTradeNetworks() as TradeNetworkType[];

    dispatch(setLoadingNetworks(false));
    dispatch(setTradeNetworks(data));
  }, [dispatch]);

  return {
    fetchTradeNetworks,
    tradeNetworks
  };
}
