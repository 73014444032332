// Copyright 2020 @po-polochkam authors & contributors
import type { ChatMessage } from './firebaseStore/entities/chatMessages';

import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Input, Space, Spin } from 'antd';
import moment from 'moment';
import firebase from 'firebase';
import 'moment/locale/ru';
import addMessage from 'assets/svg/addMessage.svg';
import clearIcon from 'assets/svg/clearIcon.svg';
import useChatFirebase from './hooks/useChatFirebase';
// import useChatUsers from './hooks/useChatUsers';
import NumberWithRoundBackground from '../NumberWithRoundBackground';
import ButtonCommon from 'uiKit/Button';

import { User } from 'api/abstractions/user';
import { getUser } from 'api/manageToken';
import '../../i18n';

import ChatMessageItem from './ChatMessageItem';
import './styles.less';
import { ChatSource, ChatType } from './types';

moment.locale('ru');

const { TextArea } = Input;

const filesAlertTimeout = 5000;

export interface Props {
  chatSourceId: string;
  firebaseDb: firebase.database.Database | undefined;
  source: ChatSource;
  chatType: ChatType;
}

const ChatComponent: React.FC<Props> = (props: Props) => {
  const { chatSourceId, chatType, firebaseDb, source } = props;
  const user: User = getUser() as User;
  const { t } = useTranslation();
  const { chatMessages, fetchMessagesById, loading, markAsRead, sendMessage } = useChatFirebase(chatSourceId, chatType, firebaseDb, source, user.userId);
  const [newMessage, setNewMessage] = useState<string>();
  const [alertText, setAlertText] = useState<string>();
  const [unread, setUnread] = useState<number>(0);
  const [files, setFiles] = useState<File[]>([]);
  const scrollBlock = useRef<HTMLDivElement>(null);
  const filesInput = useRef<HTMLInputElement>(null);

  // useChatUsers(user, firebaseDb);

  const onChangeNewMessage = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewMessage(e.target.value);
  }, []);

  const openPath = useCallback((path: string) => {
    window.open(path, '_blank', 'noopener, noreferer');
  }, []);

  const onSendMessage = useCallback(async () => {
    if (newMessage?.length || files?.length) {
      await sendMessage({
        files,
        message: newMessage
      });

      setNewMessage('');
      setFiles([]);
    }
  }, [files, newMessage, sendMessage]);

  const clearFileInput = useCallback((alertText: string) => {
    setAlertText(alertText);
    setTimeout(() => {
      setAlertText(undefined);
    }, filesAlertTimeout);

    if (filesInput && filesInput.current) {
      filesInput.current.value = '';
    }
  }, []);

  // "image/jpeg", "image/png", "application/pdf", "audio/mp3", "video/mp4"
  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (files.length >= 3) {
      clearFileInput(t('maximum files count exceeded'));

      return;
    }

    const filesToUpload: FileList = e.currentTarget.files as FileList;

    // файлов может быть не более 3х
    if (!filesToUpload || filesToUpload.length > 3) {
      clearFileInput(t('maximum files count exceeded'));

      return;
    }

    const bigFile = Array.from(filesToUpload).find((fileItem: File) => fileItem.size > 1572864);

    if (bigFile) {
      clearFileInput(t('maximum file size exceeded'));

      return;
    }

    setFiles((prevFiles) => ([...prevFiles, ...Array.from(filesToUpload)]));
  }, [clearFileInput, files, t]);

  const scrollToBottom = useCallback(() => {
    if (Object.keys(chatMessages).length && scrollBlock.current) {
      scrollBlock.current.scrollTo(0, scrollBlock.current.scrollHeight);
    }
  }, [chatMessages, scrollBlock]);

  const removeFile = useCallback((index: number) => {
    setFiles((prevFiles: File[]) => {
      const newFiles = [...prevFiles];

      newFiles.splice(index, 1);

      return newFiles;
    });
  }, []);

  useEffect(() => {
    const unreadMessages: ChatMessage[] = Object.values(chatMessages).filter((chatMessage: ChatMessage) => !chatMessage.readAt && chatMessage.userId !== user.userId);

    setUnread(unreadMessages.length);
  }, [chatMessages, user]);

  useEffect(() => {
    void fetchMessagesById();
  }, [fetchMessagesById]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  // для customer-lk
  // создаем chat с id группы обсуждения, указываем тип - report-discussion
  // создаем запись в chatMessages - по id группы обсуждения, в поле source пишем adminLk / customerLk
  // в chats обновляем lastMessage

  if (!firebaseDb) {
    return (
      <div className='chat-component'>
        {t('connecting chat')}
      </div>
    );
  }

  return (
    <div className='chat-component'>
      <h2>{t('service chat')}
        { unread > 0 && (
          <NumberWithRoundBackground
            isActiveTab={true}
            numb={unread}
          />
        )}
      </h2>
      <div
        className='chat-component--messages'
        ref={scrollBlock}
      >
        { Object.keys(chatMessages).map((chatMessageId: string) => {
          if (chatMessages[chatMessageId].userId === user.userId) {
            return (
              <div
                className='chat-component--messages--line'
                key={chatMessageId}
              >
                <ChatMessageItem
                  admin={source === 'adminLk'}
                  chatMessageId={chatMessageId}
                  className='outgoing'
                  markAsRead={markAsRead}
                  messageItem={chatMessages[chatMessageId]}
                  openPath={openPath}
                  userId={user.userId}
                />
              </div>
            );
          }

          return (
            <div
              className='chat-component--messages--line'
              key={chatMessageId}
            >
              <ChatMessageItem
                admin={source === 'adminLk'}
                chatMessageId={chatMessageId}
                className='incoming'
                markAsRead={markAsRead}
                messageItem={chatMessages[chatMessageId]}
                openPath={openPath}
                userId={user.userId}
              />
            </div>
          );
        })}
      </div>
      <div className='chat-component--write'>
        { loading && (
          <Space size='middle'>
            <Spin
              size='large'
              tip={t('loading')}
            />
          </Space>
        )}
        <TextArea
          onChange={onChangeNewMessage}
          placeholder={t('start writing a message')}
          rows={4}
          value={newMessage}
        />
        { files?.length > 0 && (
          <div className='message-files'>
            { files.map((file: File, index: number) => (
              <div
                className='message-file'
                key={file.name}
              >
                { file.name?.length > 10 && (
                  <span>{`${file.name.substr(0, 10)}...${file.name.substr(file.name.length - 4, file.name.length)}`}</span>
                )}
                { file.name?.length <= 10 && (
                  <span>{file.name}</span>
                )}
                <img
                  alt='remove-file'
                  className='remove-file'
                  onClick={removeFile.bind(null, index)}
                  src={clearIcon}
                >
                </img>
              </div>
            ))}
          </div>
        )}
        <div className='button-group'>
          <ButtonCommon
            size='large'
            type='default'
          >
            <label htmlFor='upload-file'>{(t('upload a file, max 3 items') || '').toString()}</label>
            <input
              accept='image/jpeg,image/png,application/pdf'
              id='upload-file'
              multiple
              onChange={onSelectFile}
              ref={filesInput}
              type='file'
            />
            <img
              alt='add message'
              src={addMessage}
            />
          </ButtonCommon>
          <ButtonCommon
            disabled={(!newMessage && !files?.length) || files.length > 3}
            onClick={onSendMessage}
            size='large'
            type='primary'
          >
            {(t('send') || '').toString()}
          </ButtonCommon>
        </div>
      </div>
      { alertText && (
        <Alert
          message={alertText}
          type='error'
        />
      )}
    </div>
  );
};

export default memo(ChatComponent);
