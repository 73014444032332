// Copyright 2020 @po-polochkam authors & contributors

// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback, useMemo } from 'react';

import amount from '../../assets/svg/amount.svg';
import amountBig from '../../assets/svg/amount-big.svg';
import amountActive from '../../assets/svg/amount-active.svg';
import amountActiveBig from '../../assets/svg/amount-big-active.svg';
import './styles.less';

interface NumberWithRoundBackgroundProps {
  isActiveTab: boolean;
  numb: number;
}

const NumberWithRoundBackground: React.FC<NumberWithRoundBackgroundProps> = (props) => {
  const { isActiveTab, numb } = props;

  const widthPx = useMemo((): number => {
    if (!!numb && numb.toString().length > 2 && numb.toString().length < 5) {
      return 38;
    }

    return 32;
  }, [numb]);

  const getBackgroundByTab = useCallback(() => {
    if (isActiveTab) {
      if (!!numb && numb.toString().length > 2) {
        return amountActiveBig;
      } else {
        return amountActive;
      }
    } else {
      if (!!numb && numb.toString().length > 2) {
        return amountBig;
      } else {
        return amount;
      }
    }
  }, [isActiveTab, numb]);

  return (
    <div
      className='number-with-round-background'
      style={{
        backgroundImage: `url("${getBackgroundByTab()}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        color: `${isActiveTab ? 'white' : '#1C1B21'}`,
        width: `${widthPx}px`
      }}
    >
      {numb}
    </div>
  );
};

export default memo(NumberWithRoundBackground);
