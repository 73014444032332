// Copyright 2020 @po-polochkam authors & contributors
import React, { memo } from 'react';
import moment from 'moment-timezone';

interface ChatTextMessageProps {
  avatar?: string | null;
  dateTime: string;
  message: string,
}

const utcOffset = moment.tz('Europe/Moscow').utcOffset();

const ChatTextMessage: React.FC<ChatTextMessageProps> = (props: ChatTextMessageProps) => {
  const { avatar, dateTime, message } = props;

  return (
    <>
      { avatar && (
        <div className='avatar'>
          <img
            alt='admin avatar'
            src={avatar}
          />
        </div>
      )}
      <div className='message-body'>
        <div className='message-text'>
          {message}
        </div>
        <div className='message-time'>{moment(dateTime, 'YYYY-MM-DD-HH-mm-ss').utc().add(utcOffset, 'minutes').fromNow()}</div>
      </div>
    </>
  );
};

export default memo(ChatTextMessage);
