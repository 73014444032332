// Copyright 2020 @po-polochkam authors & contributors
import type { User } from 'api/abstractions/user';
import { Filter, ResultType } from 'api/abstractions/result';
import { TradeNetworkType } from 'api/abstractions/dictionary';

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { ConfigProvider, Pagination, Spin } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/ru';
import ruRU from 'antd/lib/locale/ru_RU';

import useResults from 'hooks/useResults';

import ModalCommon from 'uiKit/Modal';
import ResultsListHeader from 'components/ResultsListHeader';
import ResultsListFooter from 'components/ResultsListFooter';
import ResultModalContent from 'components/ResultModalContent';
import { setPage, setPerPage, setViewedResults } from 'store/resutlsReducer';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { getUser } from 'api/manageToken';

import './styles.less';
import CheckboxCommon from '../../uiKit/Checkbox/CheckboxCommon';
import ResultsListItem from '../ResultsListItem';

moment.locale('ru');

// gln уникален для магазина
// у магазина еще есть shop_id uuid

interface ResultsListProps {
  canOpenResult?: boolean;
  filter: Filter;
}

const ResultsList: React.FC<ResultsListProps> = (props) => {
  const { canOpenResult, filter } = props;
  const dispatch = useAppDispatch();
  const { initialLoad, loadingResults, onApplySelected, onApplyWithoutVerification, results, resultsCount, resultsDates, startReviewingItems } = useResults();
  const page = useSelector((state: RootState) => state.results.page);
  const perPage = useSelector((state: RootState) => state.results.perPage);
  const [resultsMappedByDate, setResultsMappedByDate] = useState<{ [date: string]: { [id: string] : ResultType } }>({});
  const [selectedResults, setSelectedResults] = useState<{ [id: string] : ResultType }>({});
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const user: User = getUser() as User;
  // просмотренные результаты
  const viewedResults = useSelector((state: RootState) => state.results.viewedResults);
  const selected = useSelector((state: RootState) => state.results.resultsNetworks) as {[key: string]: TradeNetworkType};
  const searchString = useSelector((state: RootState) => state.results.resultsSearch);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { status } = filter;
  const filterStatus: string = status;

  const clearSelected = useCallback(() => {
    // очищаем выбранные
    setSelectedResults({});
    setAllChecked(false);
  }, []);

  const mapResults = useCallback(() => {
    if (filterStatus && results[filterStatus] && Object.keys(results[filterStatus]).length && results[filterStatus][page]) {
      const resultsMap = {} as { [date: string]: { [id: string] : ResultType } };

      (results[filterStatus][page]).forEach((resultItem) => {
        const newResultItem = { ...resultItem };

        if (!resultsMap[newResultItem.date]) {
          resultsMap[newResultItem.date] = {};
        }

        resultsMap[newResultItem.date][newResultItem.id] = newResultItem;
      });

      setResultsMappedByDate(resultsMap);
    } else {
      setResultsMappedByDate({});
    }
  }, [filterStatus, results, page]);

  const onClickCheckbox = useCallback((checked, result: ResultType) => {
    if (checked) {
      setSelectedResults({
        ...selectedResults,
        [result.id]: result
      });
    } else {
      /*
      Если после действия "Отметить все" пользователь снимает выделение с одной позиции,
      то выделение снимается со всех выделенных позиций списка,
      при этом на экране отключается признак "Отметить все".
      */
      if (allChecked) {
        setSelectedResults({});
        setAllChecked(false);
      } else {
        const renewSelected = { ...selectedResults };

        delete renewSelected[result.id];
        setSelectedResults(renewSelected);
      }
    }
  }, [allChecked, selectedResults, setSelectedResults]);

  const isResultChecked = useCallback((result: ResultType): boolean => {
    return !!selectedResults[result.id];
  }, [selectedResults]);

  const toggleResultModal = useCallback((isOpen: boolean) => {
    if (!isOpen && location.pathname.includes('result-modal')) {
      const indexFrom = location.pathname.indexOf('/result-modal');
      const substr = location.pathname.slice(indexFrom, location.pathname.length);

      history.push(location.pathname.replace(substr, ''));
      initialLoad('light');
    }
  }, [history, initialLoad, location]);

  const carryOverForVerification = useCallback(() => {
    const ids = Object.keys(selectedResults).map((resultId) => resultId);

    startReviewingItems(allChecked, ids, filterStatus);
    clearSelected();
  }, [allChecked, clearSelected, filterStatus, selectedResults, startReviewingItems]);

  const onItemClick = useCallback((resultId: string) => {
    // Здесь будет проверяться, если есть выбранные результаты и мы на вкладке "Проверка", то открываем модалку.
    if (!canOpenResult) {
      return;
    }

    history.push(`/home/results-verification/checking/result-modal/${resultId}`);
  }, [canOpenResult, history]);

  const applyWithoutVerification = useCallback(() => {
    onApplyWithoutVerification(allChecked, [...Object.keys(selectedResults)], filterStatus);
    clearSelected();
  }, [allChecked, clearSelected, filterStatus, onApplyWithoutVerification, selectedResults]);

  const applySelected = useCallback(() => {
    onApplySelected(allChecked, [...Object.keys(selectedResults)], filterStatus);
    clearSelected();
  }, [allChecked, clearSelected, filterStatus, onApplySelected, selectedResults]);

  const onShowSizeChange = useCallback((current: number, pageSize: number) => {
    dispatch(setPerPage(pageSize));
  }, [dispatch]);

  const onChangePage = useCallback((pageNumber: number) => {
    dispatch(setPage(pageNumber));
  }, [dispatch]);

  useEffect(() => {
    mapResults();
  }, [mapResults]);

  // адреса будут приходить по апи, поэтому здесь они не локализованы
  return (
    <div className='results-list'>
      <ResultsListHeader
        allChecked={allChecked}
        count={filterStatus ? resultsCount[filterStatus] : 0}
        resultsMappedByDate={resultsMappedByDate}
        selectedResults={selectedResults}
        setAllChecked={setAllChecked}
        setSelectedResults={setSelectedResults}
      />
      { (resultsDates || Object.keys(selected).length > 0 || searchString) && (
        <div className='results-filter-text'>
          { resultsDates && (
            <span> {t('shown results')}: {moment(resultsDates[0], 'YYYY-MM-DD').format('DD MMMM, YYYY г.')} — {moment(resultsDates[1], 'YYYY-MM-DD').format('DD MMMM, YYYY г.')}</span>
          )}
          { Object.keys(selected).length > 0 && (
            <span> {t('network')}: {Object.values(selected).map((item) => item.name).join(', ')}</span>
          )}
          { searchString && (
            <span> {t('search')}: {searchString}</span>
          )}
        </div>
      )}
      { (filterStatus && results[filterStatus] && Object.keys(results[filterStatus]).length > 0) && (
        <ConfigProvider locale={ruRU}>
          <Pagination
            current={page}
            defaultPageSize={perPage}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showQuickJumper
            showSizeChanger
            showTitle={false}
            total={filterStatus ? resultsCount[filterStatus] : 0}
          />
        </ConfigProvider>
      )}
      <div className='list-items'>
        { loadingResults && (
          <div className='mu-spin-container'>
            <Spin
              key={'results-list'}
              size='large'
              tip={t('loading')}
            />
          </div>
        )}
        { (!loadingResults && Object.keys(resultsMappedByDate).length > 0) && Object.keys(resultsMappedByDate).map((dateKey: string) => (
          <div
            className='list-items-block'
            key={dateKey}
          >
            <div className='list-item-header'>
              {moment(dateKey, 'YYYY-MM-DD').format('DD MMMM YYYY')}
            </div>
            <div className='list-items-body'>
              { Object.values(resultsMappedByDate[dateKey]).map((result: ResultType) => (
                <div
                  className='list-item'
                  key={result.id}
                >
                  <CheckboxCommon
                    checked={isResultChecked(result)}
                    name={`${result.id}`}
                    onChange={(checked) => onClickCheckbox(checked, result)}
                  />
                  <ResultsListItem
                    active={viewedResults[`${user?.id}-${result.id}`]}
                    address={`${result.shopAddress?.cityName || ''} ${result?.shopAddress?.street || ''}`}
                    category={''}
                    gln={result.shop.gln}
                    id={result.id}
                    img={result.tradeNetwork.icon}
                    name={result.tradeNetwork.name}
                    onItemClick={(resultId: string) => {
                      // сохраняем результат, как уже просмотренный
                      dispatch(setViewedResults({ ...viewedResults, [`${user.id}-${resultId}`]: true }));
                      onItemClick(resultId);
                    }}
                    selected={isResultChecked(result)}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      { (filterStatus && results[filterStatus] && Object.keys(results[filterStatus]).length > 0) && (
        <ConfigProvider locale={ruRU}>
          <Pagination
            current={page}
            defaultPageSize={perPage}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showQuickJumper
            showSizeChanger
            showTitle={false}
            total={filterStatus ? resultsCount[filterStatus] : 0}
          />
        </ConfigProvider>
      )}
      <br />
      <br />
      <br />
      <br />
      { Object.values(selectedResults).length > 0 && (
        <ResultsListFooter
          applySelected={applySelected}
          applyWithoutVerification={applyWithoutVerification}
          carryOverForVerification={carryOverForVerification}
          resultsCount={allChecked && filterStatus ? resultsCount[filterStatus] : Object.values(selectedResults).length}
          status={filterStatus}
        />
      )}
      <Switch>
        <Route
          key='result-modal'
          path='*/result-modal/:resultId'
        >
          <ModalCommon
            modalContent={<ResultModalContent toggleResultModal={toggleResultModal} />}
            setVisible={toggleResultModal}
            visible={true}
            width={1014}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default memo(ResultsList);
