// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useMemo, useCallback } from 'react';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

import magnit from '../../assets/svg/magnit.svg';
import './styles.less';

export interface ResultsListItemInterface {
  active?: boolean;
  id: string;
  address: string;
  category: string;
  img: string;
  gln: string;
  name: string;
  onItemClick?: (resultId: string) => void;
  selected?: boolean;
}

const ResultsListItem: React.FC<ResultsListItemInterface> = (props) => {
  const { active, address, category, gln, id, img, name, onItemClick, selected } = props;
  const searchString = useSelector((state: RootState) => state.results.resultsSearch);

  const boldString = useCallback((str: string) => {
    if (!searchString) {
      return str;
    }

    const strRegExp = new RegExp(searchString, 'g');

    const newStr = str.replace(strRegExp, `<b>${searchString}</b>`);

    return <span dangerouslySetInnerHTML={{ __html: newStr }} />;
  }, [searchString]);

  const className = useMemo(() => {
    return selected ? 'results-list-item selected' : active ? 'results-list-item active' : 'results-list-item';
  }, [active, selected]);

  return (
    <div
      className={className}
      onClick={onItemClick && onItemClick.bind(null, id)}
    >
      <div className='block name'>
        <img
          alt={name}
          src={img || magnit}
        />
        {boldString(name)}
      </div>
      <div className='block category'>
        {boldString(category)}
      </div>
      <div
        className='block address'
        title={address}
      >
        {boldString(address)}
      </div>
      <div className='block gln'>
        {boldString(gln)}
      </div>
    </div>
  );
};

export default memo(ResultsListItem);
