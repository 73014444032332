// Copyright 2020 @po-polochkam authors & contributors

import React, { memo } from 'react';
import './styles.less';

interface ErrorItemProps {
  text: string;
}

const ErrorItem: React.FC<ErrorItemProps> = ({ text }) => {
  return (
    <div
      className='error-item'
      title={text}
    >
      {text}
    </div>
  );
};

export default memo(ErrorItem);
