// Copyright 2020 @po-polochkam authors & contributors
import type { ChatMessage } from './firebaseStore/entities/chatMessages';
import React, { memo, useRef, useEffect } from 'react';
import useIntersection from './hooks/useIteraction';
import ChatTextMessage from './ChatTextMessage';
import ChatFileMessage from './ChatFileMessage';
import defaultAdminAvatar from './assets/svg/defaultAdminAvatar.svg';
import defaultCustomerAvatar from './assets/svg/defaultCustomerAvatar.svg';

interface ChatFileMessageProps {
  admin: boolean;
  chatMessageId: string;
  className: string;
  markAsRead: (chatMessageId: string) => void;
  messageItem: ChatMessage;
  openPath: (path: string) => void;
  userId: string;
}

const ChatMessageItem: React.FC<ChatFileMessageProps> = (props: ChatFileMessageProps) => {
  const { admin, chatMessageId, className, markAsRead, messageItem, openPath, userId } = props;
  const ref = useRef<HTMLDivElement>(null);
  const inViewport = useIntersection(ref, '0px');
  const timeout = useRef<any>();

  // Непросмотренное сообщение становится просмотренным через 3 секунды после того, как оно попадает на экране в зону видимости пользователя.
  useEffect(() => {
    if (!messageItem.readAt && inViewport && messageItem.userId !== userId) {
      timeout.current = setTimeout(() => {
        markAsRead(chatMessageId);
      }, 3000);
    }
  }, [chatMessageId, inViewport, markAsRead, messageItem, userId]);

  const avatar = admin ? defaultCustomerAvatar : defaultAdminAvatar;

  return (
    <div
      className={`${className} message ${messageItem.readAt ? 'read' : 'new'}`}
      ref={ref}
    >
      { !!(messageItem.type === 'text' && messageItem.message) && (
        <ChatTextMessage
          avatar={className === 'incoming' ? avatar : null}
          dateTime={messageItem.dateTime}
          message={messageItem.message}
        />
      )}
      { !!(messageItem.type !== 'text' && messageItem.files?.length) && (
        <ChatFileMessage
          avatar={className === 'incoming' ? avatar : null}
          dateTime={messageItem.dateTime}
          files={messageItem.files}
          openPath={openPath}
        />
      )}
    </div>
  );
};

export default memo(ChatMessageItem);
