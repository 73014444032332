// Copyright 2020 @po-polochkam authors & contributors
import type { ItemType } from 'api/abstractions/dictionary';

import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/SearchInput';
import CheckboxCommon from 'uiKit/Checkbox/CheckboxCommon';
import './styles.less';

interface SearchDropdownProps {
  clearFilter: () => void;
  items: ItemType[];
  loading: boolean | undefined;
  multiple?: boolean;
  onSelectItem: (item: ItemType) => void;
  searchString: string | undefined;
  selected: {[key: string]: ItemType};
  setSearchString: (searchString: string | undefined) => void;
}

const SearchDropdown: React.FC<SearchDropdownProps> = (props) => {
  const {
    clearFilter,
    items,
    loading,
    multiple,
    onSelectItem,
    searchString,
    selected,
    setSearchString
  } = props;
  const { t } = useTranslation();

  return (
    <div className='search-dropdown'>
      <div className='search-dropdown--header'>
        { multiple ? t('select city') : t('select region')}
        { (!loading && Object.keys(selected).length > 0) && (
          <a
            className='search-dropdown--header--clear'
            onClick={clearFilter}
          >
            {t('clear filter')}
          </a>
        )}
      </div>
      <div className='search-dropdown--search'>
        <SearchInput
          onChange={setSearchString}
          onClearSearch={setSearchString.bind(null, undefined)}
          placeholder={(t('enter first two symbols') || '').toString()}
          value={searchString}
        />
      </div>
      <div className='search-dropdown--list'>
        { (!loading && items) && items.map((item: ItemType) => {
          const { id, name }: { id: string, name: string } = item;

          return (
            <div
              className={`search-dropdown--list--item ${!multiple && selected[id] ? 'selected' : ''}`}
              key={id}
              onClick={multiple ? () => null : onSelectItem.bind(null, item)}
            >
              { multiple && (
                <CheckboxCommon
                  checked={!!(selected[id])}
                  name={`${id}`}
                  onChange={onSelectItem.bind(this, item)}
                >
                  {name}
                </CheckboxCommon>
              )}
              { !multiple && name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchDropdown;
