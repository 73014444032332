// Copyright 2020 @po-polochkam authors & contributors
import React, { memo } from 'react';
import './styles.less';

interface ImageThumbnailProps {
  active: boolean;
  ind: number;
  setActive: (src: string) => void;
  src: string;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = (props) => {
  const { active, ind, setActive, src } = props;

  return (
    <div
      className='image-thumbnail'
      onClick={setActive.bind(null, src)}
    >
      <img
        alt='thumbnail'
        src={src}
      />
      <div className={`image-thumbnail--index ${active ? 'active' : ''}`}>
        {ind}
      </div>
    </div>
  );
};

export default memo(ImageThumbnail);
