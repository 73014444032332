// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { Empty, Space, Spin } from 'antd';
import type { ReportMistakeType, ReportResultType } from 'api/abstractions/result';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { setViewedReports } from 'store/resutlsReducer';
import ErrorSelector from 'components/ErrorSelector';
import ResultsImageGallery from 'components/ResultsImageGallery';
import LackOfProducts from 'components/LackOfProducts';
import useResult from 'hooks/useResult';
import useIsMountedRef from 'hooks/useIsMountedRef';
import addItemIcon from 'assets/svg/addItem.svg';
import './styles.less';

// эти данные придут по апи
const LayoutContent: React.FC<{ mayEdit: boolean; reportId?: string; resultId: string }> = ({ mayEdit, reportId, resultId }) => {
  const [resultToCheck, setResultToCheck] = useState<ReportResultType>();
  const [sortedReports, setSortedReports] = useState<ReportResultType[]>([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const mountedRef = useIsMountedRef();
  const { loadingResult, selectedResult } = useResult(resultId);
  const viewedReports = useSelector((state: RootState) => state.results.viewedReports);
  const [galleryActiveImage, setGalleryActiveImage] = useState<string>('');
  const timeout = useRef<any>();

  const onMouseOut = useCallback(() => {
    clearTimeout(timeout.current);
  }, []);

  const onMouseOver = useCallback((item: ReportResultType) => {
    if (!viewedReports[item.id]) {
      timeout.current = setTimeout(() => {
        dispatch(setViewedReports({ ...viewedReports, [item.id]: true }));
      }, 5000);
    }
  }, [dispatch, viewedReports]);

  const openResultGallery = useCallback((item: ReportResultType) => {
    setResultToCheck(item);
    dispatch(setViewedReports({ ...viewedReports, [item.id]: true }));
  }, [dispatch, viewedReports]);

  useEffect(() => {
    if (selectedResult && selectedResult.reportResults) {
      const sorted = [...selectedResult.reportResults].filter((item) => item.id !== reportId);

      mountedRef.current && setSortedReports(sorted.sort((a, b) => a.productCategory.name.localeCompare(b.productCategory.name)));
    }
  }, [mountedRef, reportId, selectedResult]);

  useEffect(() => {
    if (selectedResult && selectedResult?.reportResults.length) {
      selectedResult.reportResults.forEach((item: ReportResultType) => {
        if (item.reportResultReportMistakes.length && !viewedReports[item.id]) {
          dispatch(setViewedReports({ ...viewedReports, [item.id]: true }));
        }
      });
    }
  }, [dispatch, selectedResult, viewedReports]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <div className='result-layout'>
      { loadingResult && (
        <Space size='middle'>
          <Spin
            size='large'
            tip={t('loading')}
          />
        </Space>
      )}
      { !sortedReports?.length && !loadingResult && (
        <Empty
          description={t('there are no results on current date')}
        />
      )}
      { sortedReports?.length > 0 && (
        <ul>
          { sortedReports.map((item: ReportResultType, index) => (
            <li
              className={viewedReports[item.id] ? 'viewed' : ''}
              key={item.id}
              onMouseOut={onMouseOut}
              onMouseOver={onMouseOver.bind(null, item)}
            >
              { (!sortedReports[index - 1] || sortedReports[index - 1].productCategory.name !== item.productCategory.name) && (
                <header>{item.productCategory.name}</header>
              )}
              <div className='category-block'>
                <div className='category-block--example'>
                  <img
                    alt='photoReport'
                    onClick={() => {
                      openResultGallery(item);
                      setGalleryActiveImage(item.photoReport.imagePath);
                    }}
                    src={item.photoReport.imagePath}
                  />
                </div>
                { !item.customerReportPhotoResults.length && (
                  <div />
                )}
                { item.customerReportPhotoResults.length === 1 && (
                  <div className='category-block--customer-image'>
                    <div className='category-block--customer-images--image--index'>1</div>
                    <img
                      alt='customer-photo'
                      onClick={() => {
                        openResultGallery(item);
                        setGalleryActiveImage(item.photoReport.imagePath);
                      }}
                      src={item.customerReportPhotoResults[0].imagePath}
                    />
                  </div>
                )}
                { item.customerReportPhotoResults.length > 1 && (
                  <div className='category-block--customer-images'>
                    { item.customerReportPhotoResults.map((image, index) => (
                      <div
                        className='category-block--customer-images--image'
                        key={image.imagePath}
                      >
                        <div className='category-block--customer-images--image--index'>{index + 1}</div>
                        <img
                          alt='customer-photo'
                          onClick={openResultGallery.bind(null, item)}
                          src={image.imagePath}
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className='select-error'>
                  { (item.customerReportPhotoResults[0]?.reportAssessment?.accepted === false && item.customerReportPhotoResults[0]?.reportAssessment?.madeBy === 'operator') && (
                    <div className='error-selected'>
                      <div className='error-selected--error'>{t('report rejected by service')}</div>
                    </div>
                  )}
                  { !(item.customerReportPhotoResults[0]?.reportAssessment?.accepted === false && (item.customerReportPhotoResults[0]?.reportAssessment?.madeBy === 'operator' || item.customerReportPhotoResults[0]?.reportAssessment?.madeBy === 'automatic')) && (
                    <>
                      { (mayEdit) &&
                      <ErrorSelector
                        allowClear
                        category={item.productCategory}
                        reportResultId={item.photoReport.id}
                        resultId={resultId}
                        suffixIcon={
                          <img
                            alt='add item'
                            src={addItemIcon}
                          />
                        }
                      />
                      }
                      { item.reportResultReportMistakes && item.reportResultReportMistakes.length > 0 && item.reportResultReportMistakes.map((mistake: ReportMistakeType) => (
                        <ErrorSelector
                          allowClear={mayEdit}
                          category={item.productCategory}
                          key={mistake.reportResultMistake.id || mistake.id}
                          mistake={mistake}
                          reportResultId={item.photoReport.id}
                          resultId={resultId}
                          suffixIcon={
                            (mayEdit === true)
                              ? <img
                                alt='add item'
                                src={addItemIcon}
                              />
                              : <> </>
                          }
                        />
                      ))}
                    </>
                  )}
                  <div className='error-selected'>
                    <div className='error-selected--header'>{t('performers comments')}</div>
                    {item.customerReportPhotoResults.map((item, index: number) => {
                      if (item.comment) {
                        return (
                          <div
                            className='error-selected--comment'
                            key={item.comment}
                          >
                            {index + 1}. {item.comment}
                          </div>
                        );
                      }
                    })}
                  </div>
                  <LackOfProducts
                    categoryId={item.productCategory.id}
                    reportId={item.photoReport.id}
                    resultId={resultId}
                  />
                  { (item.customerReportPhotoResults[0]?.reportAssessment && item.customerReportPhotoResults[0]?.reportAssessment.madeBy === 'automatic') && (
                    <div className='error-selected'>
                      <div className='error-selected--header'>{t('recognition solution')}</div>
                      <div className='error-selected--comment'>{item.customerReportPhotoResults[0].reportAssessment.accepted ? t('report approved') : t('report rejected')}</div>
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {(resultToCheck && resultToCheck.customerReportPhotoResults.length) && (
        <ResultsImageGallery
          activeReportSampleImage={galleryActiveImage}
          resultToCheck={resultToCheck}
          setResultToCheck={setResultToCheck}
        />
      )}
    </div>
  );
};

export default memo(LayoutContent);
