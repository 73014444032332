// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import NumberWithRoundBackground from 'components/NumberWithRoundBackground';
import './styles.less';

interface TabHeaderWithCounterProps {
  activeClass?: boolean;
  numb?: number;
  tabKey: string;
  text: string;
}

const TabHeaderWithCounter: React.FC<TabHeaderWithCounterProps> = (props) => {
  const { activeClass, numb, tabKey, text } = props;
  const location = useLocation();

  const isActiveTab = useMemo(() => {
    return location.pathname.includes(tabKey);
  }, [tabKey, location]);

  return (
    <div className={`tab-header-with-number ${activeClass && isActiveTab ? 'active' : ''}`}>
      <span>{text}</span>
      <NumberWithRoundBackground
        isActiveTab={isActiveTab}
        numb={numb || 0}
      />
    </div>
  );
};

export default memo(TabHeaderWithCounter);
