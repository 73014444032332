// Copyright 2020 @po-polochkam authors & contributors
import { ReportResultGroup, ResultGroupCustomerMap } from 'api/abstractions/result';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import 'moment/locale/ru';
import ModalCommon from 'uiKit/Modal';
import DiscussionModalContent from 'components/DiscussionModalContent';
import useReportResultGroups from 'hooks/useReportResultGroups';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { setLoadingResultGroups, setPage, setPerPage, setViewedDiscussionResults } from 'store/resutlsReducer';
import useResultsItems from 'hooks/useResultsItems';
import useFirebaseDb from 'components/ChatComponent/hooks/useFirebaseDb';
import './styles.less';
import { ConfigProvider, Pagination, Spin } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import DiscussListItem from '../DiscussListItem';
import { useTranslation } from 'react-i18next';
import { User } from 'api/abstractions/user';
import { getUser } from 'api/manageToken';
import DateFilter from '../DateFilter';
import NetworkFilter from '../NetworkFilter';

moment.locale('ru');

const DiscussionList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { groupsCount, resultGroups } = useReportResultGroups();
  const { initialLoad } = useResultsItems();
  const { t } = useTranslation();
  const [resultsMappedByDate, setResultsMappedByDate] = useState<ResultGroupCustomerMap>({});
  const viewedDiscussionResults = useSelector((state: RootState) => state.results.viewedDiscussionResults);
  const page = useSelector((state: RootState) => state.results.page);
  const perPage = useSelector((state: RootState) => state.results.perPage);
  const loadingResultGroups = useSelector((state: RootState) => state.results.loadingResultGroups);
  const history = useHistory();
  const location = useLocation();
  const user: User = getUser() as User;
  const { firebaseDb } = useFirebaseDb(user);

  const mapResults = useCallback(() => {
    /* Здесь мы берем на входе плоский список (постранично) - resultGroups[page] и превращаем его в маппинг
    по 2 ключам - дате и id магазина
     */
    if (Object.keys(resultGroups)?.length > 0) {
      const resultsMap: ResultGroupCustomerMap = {};

      resultGroups.forEach((resultItem: ReportResultGroup) => {
        const newResultItem: ReportResultGroup = { ...resultItem };

        if (!resultsMap[newResultItem.customerDailyResult.date]) {
          resultsMap[newResultItem.customerDailyResult.date] = {};
        }

        if (!resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.shop.id]) {
          resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.shop.id] = {};
        }

        resultsMap[newResultItem.customerDailyResult.date][newResultItem.customerDailyResult.shop.id][newResultItem.id] = newResultItem;
      });

      setResultsMappedByDate(resultsMap);
    } else {
      setResultsMappedByDate({});
    }
  }, [resultGroups]);

  const closeModal = useCallback(() => {
    if (location.pathname.includes('discussion-modal')) {
      const indexFrom = location.pathname.indexOf('/discussion-modal');
      const substr = location.pathname.slice(indexFrom, location.pathname.length);

      history.push(location.pathname.replace(substr, ''));
    }
  }, [history, location]);

  const toggleResultModal = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      closeModal();
      initialLoad('light');
    }
  }, [closeModal, initialLoad]);

  const onItemClick = useCallback((resultId: string) => {
    dispatch(setViewedDiscussionResults({ ...viewedDiscussionResults, [`${user.id}-${resultId}`]: true }));
    history.push(`/home/results-verification/in-discussion/discussion-modal/${resultId}`);
  }, [dispatch, history, user.id, viewedDiscussionResults]);

  const resultGroupItems = useCallback((item: { [resultGroupId: string]: ReportResultGroup }) => {
    return Object.values(item).sort((a, b) => a.productCategory.name.localeCompare(b.productCategory.name));
  }, []);

  const onShowSizeChange = useCallback((current: number, pageSize: number) => {
    dispatch(setPerPage(pageSize));
  }, [dispatch]);

  const onChangePage = useCallback((pageNumber: number) => {
    dispatch(setLoadingResultGroups({ loading: true }));
    dispatch(setPage(pageNumber));
  }, [dispatch]);

  useEffect(() => {
    mapResults();
  }, [mapResults]);

  useEffect(() => {
    dispatch(setLoadingResultGroups({ loading: true }));
  }, [dispatch]);

  return (
    <div className='discussion-list'>
      <div className='discussion-list-filters'>
        <DateFilter />
        <NetworkFilter />
      </div>
      { Object.keys(resultGroups).length > 0 && (
        <ConfigProvider locale={ruRU}>
          <Pagination
            current={page}
            defaultPageSize={perPage}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showQuickJumper
            showSizeChanger
            showTitle={false}
            total={groupsCount || 0}
          />
        </ConfigProvider>
      )}
      <div className='list-gradient-block' />
      <div className='discussion-list--body'>
        { loadingResultGroups && (
          <div className='mu-spin-container'>
            <Spin
              key={'results-list'}
              size='large'
              tip={t('loading')}
            />
          </div>
        )}
        { (!loadingResultGroups && Object.keys(resultsMappedByDate).length > 0) && Object.keys(resultsMappedByDate).map((dateKey: string) => (
          <div
            className='list-items'
            key={dateKey}
          >
            { Object.keys(resultsMappedByDate[dateKey]).map((shopId: string) => (
              <div
                className='list-items-block'
                key={shopId}
              >
                <div className='list-item-header'>
                  <div className='list-item-header--address'>
                    <img
                      alt={Object.values(resultsMappedByDate[dateKey][shopId])[0]?.customerDailyResult.tradeNetwork.name}
                      src={Object.values(resultsMappedByDate[dateKey][shopId])[0]?.customerDailyResult.tradeNetwork.icon}
                    />
                    <span>{Object.values(resultsMappedByDate[dateKey][shopId])[0].customerDailyResult.tradeNetwork.name}</span>
                    <span>{`${Object.values(resultsMappedByDate[dateKey][shopId])[0].customerDailyResult.shopAddress?.cityName || ''} ${Object.values(resultsMappedByDate[dateKey][shopId])[0]?.customerDailyResult.shopAddress?.street || ''}`}</span>
                  </div>
                  <div className='list-item-header--date'>
                    {moment(dateKey, 'YYYY-MM-DD').format('DD MMMM YYYY')}
                  </div>
                </div>
                <div className='list-items-body'>
                  { Object.values(resultGroupItems(resultsMappedByDate[dateKey][shopId])).map((result: ReportResultGroup) => (
                    <div
                      className='list-item'
                      key={result.id}
                    >
                      <DiscussListItem
                        category={result.productCategory}
                        firebaseDb={firebaseDb}
                        /* я уже просматривал */
                        gray={viewedDiscussionResults[`${user?.id}-${result.id}`]}
                        id={result.id}
                        /* по обсуждению нет Решения сервиса или по обсуждению Решение сервиса = "ошибка заказчика" и нет решения заказчика. */
                        isOpen={result.claimApproval === 'new' || (result.claimApproval === 'declined' && result.claimStatus === 'active')}
                        mistakes={result.mistakes}
                        onItemClick={onItemClick}
                        user={user}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      { Object.keys(resultsMappedByDate).length > 0 && (
        <ConfigProvider locale={ruRU}>
          <Pagination
            current={page}
            defaultPageSize={perPage}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            showQuickJumper
            showSizeChanger
            showTitle={false}
            total={groupsCount || 0}
          />
        </ConfigProvider>
      )}
      <Switch>
        <Route
          key='discussion-modal'
          path='*/discussion-modal/:resultId'
        >
          <ModalCommon
            modalContent={
              <DiscussionModalContent
                firebaseDb={firebaseDb}
                toggleResultModal={toggleResultModal}
              />
            }
            setVisible={closeModal}
            visible={true}
            width={1014}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default memo(DiscussionList);
