// Copyright 2020 @po-polochkam authors & contributors
import type { ChatMessageFile } from './firebaseStore/entities/chatMessages';

import React, { memo } from 'react';
import moment from 'moment-timezone';

interface ChatFileMessageProps {
  avatar?: string | null;
  dateTime: string;
  files: Array<ChatMessageFile>,
  openPath: (path: string) => void;
}

const utcOffset = moment.tz('Europe/Moscow').utcOffset();

const ChatFileMessage: React.FC<ChatFileMessageProps> = (props: ChatFileMessageProps) => {
  const { avatar, dateTime, files, openPath } = props;

  return (
    <>
      { avatar && (
        <div className='avatar'>
          <img
            alt='admin avatar'
            src={avatar}
          />
        </div>
      )}
      <div className='message-body'>
        <div className='message-files'>
          { files.map((file: ChatMessageFile) => (
            <div
              className='message-file'
              key={file.path}
              onClick={openPath.bind(null, file.path)}
            >
              { file.name?.length > 10 && (
                <span>{`${file.name.substr(0, 10)}...${file.name.substr(file.name.length - 4, file.name.length)}`}</span>
              )}
              { file.name?.length <= 10 && (
                <span>{file.name}</span>
              )}
            </div>
          ))}
        </div>
        <div className='message-time'>{moment(dateTime, 'YYYY-MM-DD-HH-mm-ss').utc().add(utcOffset, 'minutes').fromNow()}</div>
      </div>
    </>
  );
};

export default memo(ChatFileMessage);
