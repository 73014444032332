// Copyright 2020 @po-polochkam authors & contributors

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useResult from 'hooks/useResult';
import TabHeaderWithCounter from 'components/TabHeaderWithCounter';
import LayoutContent from 'components/ResultModalContent/LayoutContent';
// import PromoContent from 'components/ResultModalContent/PromoContent';

const ResultModalTabsList = (resultId: string): ({ tabHeader: any; id: string; tabContent: React.ReactNode })[] => {
  const { t } = useTranslation();
  const { selectedResult } = useResult(resultId);

  return useMemo(() => [
    {
      id: 'layout',
      tabContent:
        <LayoutContent
          mayEdit={true}
          resultId={resultId}
        />,
      tabHeader:
        <TabHeaderWithCounter
          activeClass
          numb={selectedResult?.reportResults.length}
          tabKey='layout'
          text={(t('layout') || '').toString()}
        />
    }
    /* Пока не делаем, но в будущем понадобится
     {
      id: 'promo',
      tabContent:
        <PromoContent />,
      tabHeader:
        <TabHeaderWithCounter
          numb={0}
          tabKey='on-review'
          text={(t('promo') || '').toString()}
        />
    } */
  ], [resultId, selectedResult?.reportResults.length, t]);
};

export default ResultModalTabsList;
