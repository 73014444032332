// Copyright 2020 @po-polochkam authors & contributors
import { CountInterface, ResultsInitialStateInterface } from 'store/resutlsReducer';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TabHeaderWithCounter from 'components/TabHeaderWithCounter';
import ResultsList from 'components/ResultsList';
import DiscussionList from 'components/DiscussionList';

const ResultsTabsList: () => ({ tabHeader: any; id: string; tabContent: React.ReactNode })[] = () => {
  const { t } = useTranslation();
  const resultsCount = useSelector(({ results }: { results: ResultsInitialStateInterface }): CountInterface => results.count);
  const groupsCount = useSelector(({ results }: { results: ResultsInitialStateInterface }): number => results.groupsCount);

  return useMemo(() => [
    {
      id: 'on-review',
      tabContent:
        <ResultsList
          filter={{ status: 'on_customers_review' }}
        />,
      tabHeader:
        <TabHeaderWithCounter
          numb={resultsCount.on_customers_review}
          tabKey='on-review'
          text={(t('on review') || '').toString()}
        />
    },
    {
      id: 'checking',
      tabContent:
        <ResultsList
          canOpenResult
          filter={{ status: 'reviewing' }}
        />,
      tabHeader:
        <TabHeaderWithCounter
          numb={resultsCount.reviewing || 0}
          tabKey='checking'
          text={(t('checking') || '').toString()}
        />
    },
    {
      id: 'in-discussion',
      tabContent:
        <DiscussionList />,
      tabHeader:
        <TabHeaderWithCounter
          numb={groupsCount}
          tabKey='in-discussion'
          text={(t('in discussion') || '').toString()}
        />
    }
  ], [groupsCount, t, resultsCount]);
};

export default ResultsTabsList;
